import React, { useContext, useEffect, useState, useRef } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import 'styles/templateStyle.scss';
import '../Paywall/Templates/CountdownTimer/clock.scss';
import { useDispatch } from 'react-redux';
import { setAnalyticsData } from 'app/analyticsSlice';
import LoadingCircle from 'views/UserOverlay/components/loaders/LoadingCircle';
import { OverlayContext, PURCHASE_MODE, ROUTES } from 'views/UserOverlay/OverlayContext';
import * as paywallUtils from '../../../views/UserOverlay/paywallUtils';
import { postMessage } from '../../../views/UserOverlay/paywallUtils';
import { getCurrencySymbol } from 'data/currencies';
import { PaywallData } from 'views/UserOverlay/views/Welcome';
import API from 'api';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { getButtonType, BUTTON_TYPES, BUTTON_ATTRIBUTES, checkForValidation } from 'utils/dynamicPaywallUtlility';
import { eventType, getDefaultTrackingValues, eventLocation, clickAction } from 'utils/Tracker/track';
import { validateEmail, ValidateMobileNumber } from 'utils/validators';
import { setAuthDetails } from 'views/Auth/authSlice';

const TestTemplate = ({
  paywallData,
  paywallDataLoading,
  callPostLogin,
  isMobile,
}: {
  paywallData: PaywallData;
  paywallDataLoading: boolean;
  callPostLogin: ({ userId, sessionId }: { userId: string; sessionId: string }) => Promise<void>;
  isMobile: boolean;
}) => {
  const {
    userDetails,
    clientDetails,
    redirectToSubscriptionPage,
    setRoute,
    setPurchaseMode,
    couponDetails,
    loading,
    viewId,
    updateView,
    setUserDetails,
    setOnlyLoginFlow,
  } = useContext(OverlayContext);
  const paywallMainContainerId = useRef<any>();
  const authStore = useSelector((store: RootState) => store.auth);
  const [calledPrecursor, setCalledPrecursor] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const anonId = searchParams.get('anonId') || '';
  const paywallId = searchParams.get('paywallId') || '';
  const dispatch = useDispatch();
  const contentByPassEvent = async () => {
    dispatch(setAnalyticsData({ alreadyRegistered: true }));
    const contentLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
      firstTimeLogin: 0,
      clientId: clientDetails.clientId,
      anonId: anonId,
      paywallId: paywallId,
      paywallType: analyticsStore.paywallType,
    };
    API.analyst.eventPost({ ...contentLoginViewObject });
    const contentOTPregisteration = {
      ...getDefaultTrackingValues(),
      anonId: anonId,
      userId: userDetails.userId,
      userAgent: navigator.userAgent,
      eventType: 'VALIDATION',
      eventLocation: eventLocation.CONTENT_FLOW_OTP,
      validationType: 'OTP',
      firstTimeLogin: 0,
      otpVerificationStatus: 'SUCCESS',
      clientId: clientDetails.clientId,
      paywallId: paywallId,
      paywallType: analyticsStore.paywallType,
    };
    API.analyst.eventPost({ ...contentOTPregisteration });
  };
  useEffect(() => {
    dispatch(
      setAnalyticsData({
        paywallType: paywallData.data?.configuration?.[isMobile ? 'mobile' : 'desktop']?.templateId.paywallType,
      }),
    );
    if (!loading && !paywallDataLoading && paywallData && !calledPrecursor) {
      const buttonType = isMobile
        ? getButtonType(
            paywallData?.data?.configuration?.mobile?.templateId?.name as string,
            paywallData?.data?.configuration?.mobile?.templateId?.number_of_cta as number,
          )
        : getButtonType(
            paywallData?.data?.configuration?.desktop?.templateId?.name as string,
            paywallData?.data?.configuration?.desktop?.templateId?.number_of_cta as number,
          );

      // Precursors for particular templates
      precursors(
        buttonType,
        paywallData?.data?.configuration?.[isMobile ? 'mobile' : 'desktop']?.templateId?.number_of_cta as number,
      );

      //This will invoke  onclick functions according to the template
      buttonActionPerTemplate(buttonType);
      setCalledPrecursor(true);
    }
  }, [loading, paywallData, paywallDataLoading]);
  const analyticsStore = useSelector((store: RootState) => store.analytics);

  const getPaywallConfigData = () => {
    return {
      anonId: anonId,
      paywallId: paywallData.data?._id,
      numOfCta: paywallData?.data?.configuration?.[isMobile ? 'mobile' : 'desktop']?.templateId?.number_of_cta,
      paywallType: paywallData?.data?.configuration?.[isMobile ? 'mobile' : 'desktop']?.templateId?.paywallType,
      paywallDisplayType: paywallData?.data?.configuration?.[isMobile ? 'mobile' : 'desktop']?.templateId?.displayType,
      contentId: clientDetails.clientContentId,
      clientId: clientDetails.clientId,
    };
  };
  const sendRegwallGoogleClickEvents = () => {
    const regwallClickEvents = {
      ...getDefaultTrackingValues(),
      ...getPaywallConfigData(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.PAYWALL,
      clickAction: clickAction.GOOGLE,
    };
    API.analyst.eventPost({ ...regwallClickEvents });
  };
  const sendRegwallClickEvents = (type: string) => {
    let clickActionType = '';
    if (type === 'emailLogin') {
      clickActionType = clickAction.EMAIL;
    } else if (type === 'mobileLogin') {
      clickActionType = clickAction.MOBILE;
    }
    const regwallClickEvents = {
      ...getDefaultTrackingValues(),
      ...getPaywallConfigData(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.PAYWALL,
      clickAction: clickActionType,
    };
    API.analyst.eventPost({ ...regwallClickEvents });
  };
  //fake ocommit
  const sendPaywallClickEvent = (clickType: 'CONTENT' | 'PASS' | 'SUBS') => {
    const paywallClickData = {
      ...getDefaultTrackingValues(),
      ...getPaywallConfigData(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.PAYWALL,
      clickAction: clickType,
      userId: authStore.userId,
    };
    API.analyst.eventPost({ ...paywallClickData });
  };
  useEffect(() => {
    if (!paywallDataLoading) {
      const paywallViewObject = {
        ...getDefaultTrackingValues(),
        ...getPaywallConfigData(),
        eventType: eventType.VIEW,
        eventLocation: eventLocation.PAYWALL,
        userId: authStore.userId,
      };
      API.analyst.eventPost({ ...paywallViewObject });
    }
  }, [paywallDataLoading]);

  const notLoggedInButtonClickFunction = () => {
    setPurchaseMode(PURCHASE_MODE.CONTENT);
    updateView({ action: 'CONTENT' });
    if (paywallUtils.shouldRedirect()) {
      postMessage.REDIRECT_TO_CONSCENT(
        window.location.href + `&viewId=${viewId}&purchaseMode=${PURCHASE_MODE.CONTENT}`,
      );
    } else {
      postMessage.WELCOME_PAGE_LEFT();
      setRoute(ROUTES.LOGIN);
    }
  };

  const subscriptionLoginButton = () => {
    setPurchaseMode(PURCHASE_MODE.SUBSCRIPTION);
    updateView({ action: 'LOGIN' });
    if (paywallUtils.shouldRedirect()) {
      postMessage.REDIRECT_TO_CONSCENT(
        window.location.href + `&viewId=${viewId}&purchaseMode=${PURCHASE_MODE.SUBSCRIPTION}`,
      );
    } else {
      postMessage.WELCOME_PAGE_LEFT();
      setRoute(ROUTES.LOGIN);
    }
  };

  const googleLoginBtnForRegwall = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const searchParams = new URLSearchParams(location.search);
      const anonId = searchParams.get('anonId') || '';
      const paywallId = searchParams.get('paywallId') || '';
      const googleLoginResponse = await API.auth.GoogleLogin({
        token: tokenResponse.access_token,
        clientId: clientDetails.clientId,
        anonId: anonId,
        eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
        paywallId,
        paywallType: analyticsStore.paywallType,
      });
      if (!API.validators.checkSuccessCode(googleLoginResponse)) return console.error(googleLoginResponse.data.message);
      if (API.validators.checkSuccessCode(googleLoginResponse)) {
        const { token, email, userId, sessionId, expiresAt, version } = googleLoginResponse.data as any;
        dispatch(setAuthDetails({ sessionId, expiresAt, token, userId, email, userType: 'USER', version }));
        callPostLogin({ userId, sessionId });
      }
    },
    flow: 'implicit',
  });

  const regwallLoginAndNormalLoginButton = (type: BUTTON_ATTRIBUTES, value: string) => {
    const userDetailsData = {
      phoneNumber: ValidateMobileNumber(value) ? value : '',
      email: validateEmail(value) ? value : '',
      name: type === BUTTON_ATTRIBUTES.NAMEINPUT ? value : '',
    };
    if (paywallUtils.shouldRedirect()) {
      const templateId = paywallData?.data?.configuration?.[isMobile ? 'mobile' : 'desktop']?.templateId?._id;
      postMessage.REDIRECT_TO_CONSCENT(
        window.location.href +
          `&viewId=${viewId}&userDetails=${JSON.stringify({ ...userDetailsData })}&templateId=${templateId}`,
      );
    } else {
      setUserDetails({
        ...userDetails,
        ...userDetailsData,
      });
      postMessage.WELCOME_PAGE_LEFT();
      setRoute(ROUTES.LOGIN);
    }
  };

  const loggedInButtonClickFunction = async () => {
    setPurchaseMode(PURCHASE_MODE.CONTENT);
    updateView({ action: 'CONTENT' });
    postMessage.WELCOME_PAGE_LEFT();
    await contentByPassEvent();
    setRoute(ROUTES.BALANCE);
  };

  const purchasePassFunction = () => {
    updateView({ action: 'PASS' });
    setPurchaseMode(PURCHASE_MODE.PASS);
    if (userDetails.loggedIn) {
      postMessage.WELCOME_PAGE_LEFT();
      setRoute(ROUTES.BALANCE);
    } else {
      if (paywallUtils.shouldRedirect()) {
        postMessage.REDIRECT_TO_CONSCENT(window.location.href + `&viewId=${viewId}&purchaseMode=${PURCHASE_MODE.PASS}`);
      } else {
        postMessage.WELCOME_PAGE_LEFT();
        setRoute(ROUTES.LOGIN);
      }
    }
  };

  const onSubscriptionClick = (landingPage: string) => {
    console.log('onSubscriptionClick');
    if (userDetails.subscriptionsExist) {
      console.log('onSubscriptionClickUser');
      const paywallType = paywallData.data?.configuration?.[isMobile ? 'mobile' : 'desktop']?.templateId.paywallType;
      return redirectToSubscriptionPage(landingPage, paywallType);
    } else {
      // redirect to subscription url
      console.log('onSubscriptionClickPostmsg');
      updateView({ action: 'SUB' });
      postMessage({
        type: 'subscription',
      });
    }
  };
  const onKeyUp = (event: any) => {
    const textbox = document.getElementById('contentDivBtn');
    const textBoxValue = event.target.value;
    if (textbox) {
      textbox.setAttribute('value', textBoxValue);
    }
    const type = textbox?.getAttribute('link-attribute') as BUTTON_ATTRIBUTES;
    const mainSubmitBtn = document.getElementById('mainSubmitBtn');
    if (!type) console.error('no link-attribute found');
    if (!mainSubmitBtn) console.error('no main submitButton found');
    if (checkForValidation(type, textBoxValue || '')) {
      mainSubmitBtn?.classList?.remove('disabled-button');
    } else {
      mainSubmitBtn?.classList?.add('disabled-button');
    }
  };

  const precursors = (type: BUTTON_TYPES, nofoCta: number) => {
    //helper functions
    const setCursorStyle = () => {
      ['contentDivBtn', 'passDivBtn', 'subDivBtn'].forEach((buttonId) => {
        const button = document.getElementById(buttonId);
        const inputBoxSubmitBtn = document.querySelector('#mainSubmitBtn');

        console.log(button, 'button');
        if (button) {
          button.style.cursor = 'auto';
        }
      });
    };
    const removeClassNameFromMultipleDivs = (classsName: string) => {
      const allClasses = document.getElementsByClassName(classsName);
      for (let i = 0; i < allClasses.length; i++) {
        const classList = allClasses[i].classList;
        classList.remove(classsName);
      }
    };

    const setInnerHtmlByAttribute = (attribute: string, value: string, valueToSet: string) => {
      const element = document.querySelectorAll(`[${attribute}="${value}"]`)?.[0];
      if (element) {
        const innerHtml = element?.innerHTML;
        element.innerHTML = `${innerHtml} ${valueToSet}`;
      }
    };

    if (type === BUTTON_TYPES.DIV) {
      //add highlight div for the first time
      removeClassNameFromMultipleDivs('button-focus-blue');
      const classListOfFirstDiv = document.querySelector('.classNameForSelection')?.classList;
      classListOfFirstDiv?.add('button-focus-blue');

      //add eventlistener for changing the active div element

      const allMainDivs = document.getElementsByClassName('classNameForSelection');

      for (let i = 0; i < allMainDivs.length; i++) {
        //@ts-ignore
        allMainDivs[i].onclick = () => {
          removeClassNameFromMultipleDivs('button-focus-blue');
          allMainDivs[i].classList.add('button-focus-blue');
        };
      }
    }

    if ([BUTTON_TYPES.REGWALL_ONLY_TEXTBOX, BUTTON_TYPES.REGWALL_TEXTBOX_SSO].includes(type)) {
      const inputBoxSubmitBtn = document.querySelector('#mainSubmitBtn');
      const removedPadding = document.getElementById('registration-fullpage-child');
      if (removedPadding) removedPadding.style.padding = '8px 42px 24px';
      document.getElementById('contentDivBtn')?.setAttribute('value', '');
      inputBoxSubmitBtn?.classList.add('disabled-button');
      const inputField = document.querySelector('#contentDivBtn');
      if (inputField) {
        inputField.addEventListener('keyup', onKeyUp);
      }
    }
    if ([BUTTON_TYPES.REGWALL_ONLY_SSO].includes(type)) {
      const removedPadding = document.getElementById('registration-fullpage-child');
      if (removedPadding) removedPadding.style.padding = '8px 42px 24px';
    }
    const loginButtonFunctionality = () => {
      const loginLeftDiv = document.getElementById('AlreadyLeftText');
      const loginRightDiv = document.getElementById('loginText');
      const conscentbalance = document.getElementById('conscentBalance');

      if (userDetails.loggedIn) {
        if (loginLeftDiv) loginLeftDiv.style.display = 'none';
        if (loginRightDiv) loginRightDiv.style.display = 'none';
      } else {
        if (conscentbalance) conscentbalance.style.display = 'none';
        if (loginRightDiv)
          loginRightDiv.onclick = () => {
            postMessage.WELCOME_PAGE_LEFT();
            setOnlyLoginFlow(true);
            setRoute(ROUTES.LOGIN);
          };
      }
    };

    const couponFunctionality = () => {
      const couponLeftText1 = document.getElementById('couponLeftText');
      const couponRightText1 = document.getElementById('couponRightText');
      const couponLeftText2 = document.getElementById('couponLeftText2');
      const couponRightText2 = document.getElementById('couponRightText2');
      const couponLeftText3 = document.getElementById('couponLeftText3');
      const couponRightText3 = document.getElementById('couponRightText3');
      let couponText = '';
      if (couponDetails.code) {
        couponText = `${couponDetails.newUsersOnly ? 'New User ?' : ''}Use Coupon ${couponDetails.code}
         to ${userDetails.consumptionVerb.toLowerCase()} for FREE.`;
      } else {
        if (couponRightText1) couponRightText1.style.display = 'none';
        if (couponRightText2) couponRightText2.style.display = 'none';
        if (couponRightText3) couponRightText3.style.display = 'none';
      }
      if (couponLeftText1) {
        couponLeftText1.innerHTML = couponText;
      }
      if (couponLeftText2) {
        couponLeftText2.innerHTML = couponText;
      }
      if (couponLeftText3) {
        couponLeftText3.innerHTML = couponText;
      }
    };

    const initiateTimer = () => {
      const timerDiv = document.getElementById('timerDiv');
      const timerText = document.getElementsByClassName('timer-text')?.[0];

      if (timerDiv && timerText) {
        const countDownDate = new Date(timerDiv?.getAttribute('timer-att') || '');

        const counter = setInterval(function () {
          const now = new Date().getTime();
          //@ts-ignore
          const distance = countDownDate - now;

          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          timerText.innerHTML = `${days}:${hours}:${minutes}:${seconds}`;
          if (distance < 0) {
            clearInterval(counter);
          }
        }, 1000);
      }
    };

    const priceSetter = () => {
      setInnerHtmlByAttribute('price-attribute', 'content_price', userDetails.formattedContentPrice.toUpperCase());
      setInnerHtmlByAttribute(
        'price-attribute',
        'pass_price',
        `${getCurrencySymbol(userDetails.passCurrency)}${Math.ceil(userDetails.passPrice * 100) / 100}`,
      );
    };
    //removing focus divs
    removeClassNameFromMultipleDivs('main-div-focus');
    removeClassNameFromMultipleDivs('text-focus');
    removeClassNameFromMultipleDivs('button-focus');
    if (type === BUTTON_TYPES.DIV && nofoCta === 1) setCursorStyle();
    //handling login button functionality and conscent balance
    loginButtonFunctionality();
    couponFunctionality();
    initiateTimer();
    priceSetter();
  };

  const buttonActionPerTemplate = (type: BUTTON_TYPES) => {
    //helper functions for buttons
    const buttonRedirectionHandler = async (domElement: Element | null) => {
      const type = domElement?.getAttribute('link-attribute') as BUTTON_ATTRIBUTES;

      if (!type) return console.error('attribute not found');
      if (type === BUTTON_ATTRIBUTES.CONTENT) {
        sendPaywallClickEvent('CONTENT');
        userDetails.loggedIn ? loggedInButtonClickFunction() : notLoggedInButtonClickFunction();
      } else if (type === BUTTON_ATTRIBUTES.PASS) {
        sendPaywallClickEvent('PASS');
        purchasePassFunction();
      } else if (type === BUTTON_ATTRIBUTES.SUBSCRIPTION) {
        sendPaywallClickEvent('SUBS');
        const landingPage = domElement?.getAttribute('link-url');
        onSubscriptionClick(landingPage as string);
      } else if (
        [
          BUTTON_ATTRIBUTES.EMAILLOGIN,
          BUTTON_ATTRIBUTES.EMAILMOBILELOGIN,
          BUTTON_ATTRIBUTES.MOBILELOGIN,
          BUTTON_ATTRIBUTES.NAMEINPUT,
        ].includes(type)
      ) {
        const value = domElement?.getAttribute('value');
        sendRegwallClickEvents(type);
        regwallLoginAndNormalLoginButton(type, value || '');
      } else if (type === BUTTON_ATTRIBUTES.GMAILLOGIN) {
        sendRegwallGoogleClickEvents();
        googleLoginBtnForRegwall();
      } else if (type === BUTTON_ATTRIBUTES.GMAILEXTERNAL) {
        postMessage.paywallClickEvent(BUTTON_ATTRIBUTES.GMAILEXTERNAL);
      } else if (type === BUTTON_ATTRIBUTES.CustomURL) {
        const fetchRedirectUrl = new URL(domElement?.getAttribute('link-url') || '');
        const pageUrl = new URLSearchParams(location.search).get('pageUrl') as string;
        fetchRedirectUrl.searchParams.set('originURL', decodeURIComponent(pageUrl));
        window.parent.location.href = fetchRedirectUrl.href;
      }
    };

    if ([BUTTON_TYPES.DIV, BUTTON_TYPES.RADIO].includes(type)) {
      const button = document.getElementById('mainSubmitBtn');

      if (button) {
        button.onclick = () => {
          if (type === BUTTON_TYPES.RADIO) {
            //get which radio button is selected
            const selectedRadioButton = document.querySelector('input[name="template5fbRadio"]:checked');
            buttonRedirectionHandler(selectedRadioButton);
          } else {
            //get which div is highlighted
            const selectedDiv = document.querySelector('.button-focus-blue');
            buttonRedirectionHandler(selectedDiv);
          }
        };
      }
    } else if (
      [BUTTON_TYPES.REGWALL_ONLY_TEXTBOX, BUTTON_TYPES.REGWALL_ONLY_SSO, BUTTON_TYPES.REGWALL_TEXTBOX_SSO].includes(
        type,
      )
    ) {
      const textBoxSubmitButton = document.getElementById('mainSubmitBtn');
      const ssoSubmitButton = document?.getElementById('passDivBtn');

      if ([BUTTON_TYPES.REGWALL_ONLY_TEXTBOX, BUTTON_TYPES.REGWALL_TEXTBOX_SSO].includes(type) && textBoxSubmitButton) {
        textBoxSubmitButton.onclick = () => {
          if (!textBoxSubmitButton.classList.contains('disabled-button')) {
            const inputBox = document?.querySelector('#contentDivBtn');
            buttonRedirectionHandler(inputBox);
          }
        };
        const inputBox = document?.getElementById('contentDivBtn');
        if (inputBox) {
          inputBox?.addEventListener('keyup', (e) => {
            if (e.key === 'Enter') {
              if (!textBoxSubmitButton.classList.contains('disabled-button')) {
                buttonRedirectionHandler(inputBox);
              }
            }
          });
        }
      }

      if ([BUTTON_TYPES.REGWALL_TEXTBOX_SSO, BUTTON_TYPES.REGWALL_ONLY_SSO].includes(type) && ssoSubmitButton) {
        ssoSubmitButton.onclick = () => {
          buttonRedirectionHandler(ssoSubmitButton);
        };
      }
    } else {
      ['contentDivBtn', 'passDivBtn', 'subDivBtn'].forEach((buttonId) => {
        const button = document.getElementById(buttonId);
        if (button) {
          button.onclick = () => {
            buttonRedirectionHandler(button);
          };
        }
      });
    }
  };

  if (loading) {
    return <LoadingCircle />;
  }

  useEffect(() => {
    if (paywallMainContainerId.current) {
      const elementHeight = paywallMainContainerId.current.clientHeight;
      postMessage.SET_IFRAME_DIMENSION(elementHeight);
    }
  }, []);
  const html = isMobile
    ? paywallData.data?.configuration?.mobile?.content
    : paywallData.data?.configuration?.desktop?.content;

  return <div dangerouslySetInnerHTML={{ __html: html || '' }} ref={paywallMainContainerId} />;
};

export default TestTemplate;
