import React, { useContext, useState, useEffect } from 'react';
import style from 'views/UserOverlay/views/loginAndVerify.module.scss';
import { Wrapper } from '../components';
import { AddMoney } from '../components/AddMoney';
import { OverlayContext } from '../OverlayContext';
import PaymentFooter from './PaymentFooter';

export function Balance(): JSX.Element {
  const overlayContext = useContext(OverlayContext);
  const {
    userDetails,
    routeData,
    redirectMode,
    standaloneMode,
    fullscreenMode,
    purchaseMode,
    downloadExists,
  } = overlayContext;
  const [showLowBalance, setShowLowBalance] = useState(true);
  const [contentPrice, setContentPrice] = useState('');

  useEffect(() => {
    // @ts-ignore
    setShowLowBalance(!!routeData.showLowBalance);
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <AddMoney setContentPrice={setContentPrice} />
    </div>
  );
}
