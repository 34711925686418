import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from 'api';
import * as paywallUtils from '../paywallUtils';
import { OverlayContext, ROUTES } from '../OverlayContext';
import { Button, ButtonStyles } from '.';
import { RootState } from 'app/rootReducer';
import style from './addMoney.module.scss';
import { fbPixelConfig } from '../../../utils/fbPixelConfig';
import { Currencies } from '../../../data/currencies';
import { getCurrencySymbol } from '../../../data/getCurrencySymbol';
import axios from 'axios';
import { getDefaultTrackingValues, eventType, eventLocation, clickAction } from '../../../utils/Tracker/track';
import mobileRedirectionMessage from 'utils/notifyAppToRedirect';

// import { getClientId } from 'Tracker/CommonFunction';
import {
  validateCurrencyAmount,
  makeRzpPayment,
  makePaytmPayment,
  includeRazorpay,
  includePaytm,
  deinitializePayments,
} from '../../../utils/paymentUtility';
import { minAmountForCurrencies } from 'data/minimumAmountForCurrencies';
import LoadingCircle from './loaders/LoadingCircle';
import { toNumber } from 'lodash';

export const AddMoney = ({ setContentPrice }: { setContentPrice: any }) => {
  const overlayContext = useContext(OverlayContext);
  const {
    clientDetails,
    userDetails,
    setRoute,
    setRouteData,
    routeData,
    standaloneMode,
    updateView,
    viewId,
    couponDetails,
    couponForPass,
    purchaseMode,
    contentPurchaseType,
    downloadExists,
    redirectMode,
  } = overlayContext;

  const authDetails = useSelector((store: RootState) => store.auth);
  const [error, setError] = useState('');
  const [minAmountError, setMinAmountError] = useState('');
  const [transactionError, setTransactionError] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isPresetAmountLoading, setIsPresetAmountLoading] = useState(false);
  const [isAddMoneyToWalletLoading, setIsAddMoneyToWalletLoading] = useState(false);
  const [usePaytm, setUsePaytm] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(userDetails.contentCurrency);
  const [currencyLoading, setCurrencyLoading] = useState(true);
  const [currencyUpdating, setCurrencyUpdating] = useState(false);

  // const rawPrice = purchaseMode === 'CONTENT' ? userDetails.contentPrice : userDetails.passPrice;
  // const contentPrice = Math.ceil(rawPrice * 100) / 100;
  const contentPrice = Math.ceil(userDetails.contentPrice * 100) / 100;
  const [convertedContentPrice, setConvertedContentPrice] = useState(contentPrice);
  const [currency, setCurrency] = useState(userDetails.contentCurrency);
  const [currencySymbol, setCurrencySymbol] = useState('');
  // const searchParams = new URLSearchParams(location.search);
  const [showCurrencySelect, setShowCurrencySelect] = useState(false);
  //@ts-ignore
  const minAmount = minAmountForCurrencies[userDetails.contentCurrency];
  const [balanceDeficit, setBalanceDeficit] = useState(contentPrice);
  const [minimumLoadingAmount, setMinimumLoadingAmount] = useState(
    balanceDeficit >= minAmount ? balanceDeficit : minAmount,
  );
  const [paymentAmount, setPaymentAmount] = useState(minimumLoadingAmount.toFixed(2));
  const [presetAmounts, setPresetAmounts] = useState(['50', '100', '500']);
  const [detectedCountry, setDetectedCountry] = useState('');
  const [addMoneyToWallet, setAddMoneyToWallet] = useState(minimumLoadingAmount.toFixed(2));
  const searchParams = new URLSearchParams(location.search);
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  const anonId = searchParams.get('anonId') || '';
  const paywallId = searchParams.get('paywallId') || '';

  let paytmScript: HTMLScriptElement;
  let razorpayScript: HTMLScriptElement;

  useEffect(() => {
    updateContentPrice(userDetails.contentCurrency);
  }, [purchaseMode]);

  useEffect(() => {
    if (loading === false) {
      setIsAddMoneyToWalletLoading(false);
      setIsPresetAmountLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (history.action === 'POP' && searchParams.get('status')) {
      searchParams.delete('status');
      history.replace({
        search: searchParams.toString(),
      });
    }

    {
      /* FIXME: Disabled Paytm */
    }
    // if (detectedCountry === 'IN' && currency === 'INR') {
    //   setUsePaytm(true);
    //   if (!paytmScript) paytmScript = includePaytm();
    // } else {
    //   setUsePaytm(false);
    // }

    //@ts-ignore
    const minCurrencyAmount = minAmountForCurrencies[currency];
    setPresetAmounts([
      (minCurrencyAmount * 50).toFixed(0),
      (minCurrencyAmount * 100).toFixed(0),
      (minCurrencyAmount * 500).toFixed(0),
    ]);

    return () => {
      if (paytmScript) document.body.removeChild(paytmScript);
      if (razorpayScript) document.body.removeChild(razorpayScript);
    };
  }, [currency, detectedCountry]);

  useEffect(() => {
    updateView({ action: 'ADD-MONEY' });
    razorpayScript = includeRazorpay();
    return () => {
      deinitializePayments();
    };
  }, []);

  useEffect(() => {
    for (let i = 0; i < presetAmounts.length; i++) {
      if (minimumLoadingAmount < presetAmounts[i]) {
        setAddMoneyToWallet(presetAmounts[i]);
        return;
      }
      setAddMoneyToWallet(minimumLoadingAmount);
    }
  }, [minimumLoadingAmount]);

  useEffect(() => {
    if (!authDetails.userId) {
      return setRoute(ROUTES.WELCOME);
    }

    if (routeData?.message) {
      setTransactionError(routeData.message);
    }

    (async () => {
      const userDetails = await API.user.Details();
      setPhoneNumber(userDetails.data.phoneNumber);
      setEmail(userDetails.data.email);
      if (userDetails.data.wallet.currency) {
        setCurrency(userDetails.data.wallet.currency);
        await updateContentPrice(userDetails.data.wallet.currency);
        if (userDetails.data.wallet.currency === 'INR') {
          const currencyByCountryReponse = await API.auth.DetectCountry();
          setDetectedCountry(currencyByCountryReponse.data.countryCode);
        }
      } else {
        const currencyByCountryReponse = await API.auth.DetectCountry();
        if (!API.validators.checkSuccessCode(currencyByCountryReponse))
          return setError('Failed to load geo information. Please try again later');
        setCurrency(currencyByCountryReponse.data.currency);
        setDetectedCountry(currencyByCountryReponse.data.countryCode);
        await updateContentPrice(currencyByCountryReponse.data.currency);
        setShowCurrencySelect(true);
      }
      setCurrencyLoading(false);
    })();
  }, []);
  const onSubmit = async () => {
    const type = 'payAmount';
    P2P(convertedContentPrice, 'P2P');

    if (loading === true) return;
    // e.preventDefault();

    if (type === 'payAmount') {
      setIsPresetAmountLoading(true);
    } else {
      setIsAddMoneyToWalletLoading(true);
    }
    const amount = type === 'payAmount' ? paymentAmount : addMoneyToWallet;

    const validatePaymentError = validateCurrencyAmount({
      amount: parseFloat(amount),
      currency: currency,
    });
    if (validatePaymentError) {
      return setTransactionError(validatePaymentError);
    }

    setLoading(true);
    fbPixelConfig('InitiateCheckout', {
      currency: currency,
      value: amount,
      content_ids: clientDetails.clientContentId,
    });

    const contentDetails = {
      eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      firstTimeLogin: !analyticsStore.alreadyRegistered ? 1 : 0,
      anonId: anonId,
      clientId: clientDetails.clientId,
      clientContentId: clientDetails.clientContentId,
      price: convertedContentPrice,
      currency: currency,
      userId: authDetails.userId,
      paywallId,
      paywallType: analyticsStore.paywallType,
      journeyId: localStorage.getItem('journeyId'),
      templateId: localStorage.getItem('templateId') || '',
      successFrontendUrl: `${window.location.href}&transactionId={TRANSACTION_ID}&viewId=${viewId}`,
      type: purchaseMode,
      contentPurchaseType,
      failureFrontendUrl: `${window.location.href}`.includes('&status=FAILURE')
        ? `${window.location.href}`
        : `${window.location.href}&status=FAILURE`,
      popupId: userDetails.popupId,
    } as Record<string, any>;

    if (standaloneMode) {
      contentDetails.successFrontendUrl += '&paymentSuccessful=true';
    }
    if (usePaytm) {
      fbPixelConfig('AddPaymentInfo', {
        currency: currency,
        value: amount,
        content_ids: clientDetails.clientContentId,
        contents: 'paytmPayment',
      });

      const createPaymentResponse = await API.user.payment.createPayment({
        amount: Number.parseFloat(amount),
        contentDetails,
        currency: currency,
      });

      if (!API.validators.checkSuccessCode(createPaymentResponse)) {
        setLoading(false);
        return setTransactionError('Transaction failed');
      }
      const createPaymentData = createPaymentResponse.data as { orderId: string; txnToken: string; amount: number };
      if (!createPaymentData.orderId) {
        setLoading(false);
        return setTransactionError('Failed to place order, try again later');
      }
      makePaytmPayment(
        createPaymentData.orderId,
        createPaymentData.txnToken as string,
        createPaymentData.amount as number,
        setLoading,
      );
    } else {
      fbPixelConfig('AddPaymentInfo', {
        currency: currency,
        value: amount,
        content_ids: clientDetails.clientContentId,
        contents: 'razorpayPayment',
      });
      const pgIntegrationResponse = await API.user.payment.pgIntegration({
        clientId: clientDetails.clientId,
        clientContentId: clientDetails.clientContentId,
        type: purchaseMode,
        viewId,
        anonId: anonId,
        purchaseLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
        popupId: userDetails.popupId,
        paywallId,
        firstTimeLogin: !analyticsStore.alreadyRegistered,
        contentDetails: contentDetails,
        paywallType: analyticsStore.paywallType,
      });

      if (!API.validators.checkSuccessCode(pgIntegrationResponse)) {
        setLoading(false);
        return setTransactionError(pgIntegrationResponse.data.message);
      } else {
        const transactionid = pgIntegrationResponse.data.data.transactionId;
        const pgData = pgIntegrationResponse.data.data as {
          rzpOrderId: string;
          publishableKey: string;
          logoUrl: string;
          color: string;
        };
        if (!pgData.rzpOrderId) return setError('Failed to place order, try again later');
        const redirectUrl =
          decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || '') ||
          decodeURIComponent(new URLSearchParams(location.search).get('siteUrl') || '') ||
          `${window.location.href}&transactionId=${transactionid}&viewId=${viewId}`;
        if (!razorpayScript) {
          includeRazorpay();
        }

        makeRzpPayment(
          pgData.rzpOrderId,
          pgData.color,
          pgData.logoUrl,
          userDetails.phoneNumber as string,
          userDetails.email as string,
          rzrPayCrossBtn,
          setLoading,
          pgData.publishableKey,
          redirectUrl,
          transactionid,
        );
      }
    }
  };
  useEffect(() => {
    addMoneyPageView();
    onSubmit();
  }, []);
  const addMoneyPageView = () => {
    const addMoneyPageViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      userId: userDetails.userId,
      contentId: clientDetails.clientContentId,
      clientId: clientDetails.clientId,
      anonId: anonId,
      firstTimeLogin: !analyticsStore.alreadyRegistered ? 1 : 0,
      paywallId,
      paywallType: analyticsStore.paywallType,
    };
    API.analyst.eventPost({ ...addMoneyPageViewObject });
  };
  const rzrPayCrossBtn = (transactionId: string) => {
    if (redirectMode) return window.location.replace(decodeURIComponent(searchParams.get('pageUrl') as string));

    if (standaloneMode) {
      mobileRedirectionMessage();
      return setRoute(ROUTES.WELCOME);
    }

    searchParams.delete('status');
    history.replace({
      search: searchParams.toString(),
    });
    setTimeout(() => paywallUtils.postMessage.WELCOME_PAGE_REACHED(), 300);
    setTimeout(() => setRoute(ROUTES.WELCOME), 300);
    const tempUserObject = {
      email: userDetails.email,
      phoneNumber: userDetails.phoneNumber,
      userId: userDetails.userId,
    };
    paywallUtils.postMessage.RZPCross(tempUserObject);
    const rzrPayCrossBtnObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      firstTimeLogin: !analyticsStore.alreadyRegistered ? 1 : 0,
      clickAction: clickAction.PAYMENT_GATEWAY_CROSS_BTN,
      amount: convertedContentPrice,
      currency: currency,
      userId: userDetails.userId,
      contentId: clientDetails.clientContentId,
      clientId: clientDetails.clientId,
      anonId: anonId,
      paywallId,
      paywallType: analyticsStore.paywallType,
    };
    const response = API.user.payment.abortTransaction({
      id: transactionId,
      type: 'ORDER',
      event: rzrPayCrossBtnObject,
    });
  };
  const P2P = (price: number, type: string) => {
    const P2PObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      firstTimeLogin: !analyticsStore.alreadyRegistered ? 1 : 0,
      clickAction: type,
      amount: price,
      currency: currency,
      userId: userDetails.userId,
      contentId: clientDetails.clientContentId,
      clientId: clientDetails.clientId,
      anonId: anonId,
      paywallId,
      paywallType: analyticsStore.paywallType,
      purchaseType: purchaseMode,
    };
    API.analyst.eventPost({ ...P2PObject });
  };

  const checkAndSetError = (value: string) => {
    const paymentVal = Number.parseFloat(value);
    if (Number.isNaN(paymentVal)) return setMinAmountError('Invalid amount');

    if (paymentVal < minimumLoadingAmount) {
      return setMinAmountError('Minimum addition is ' + currencySymbol + Math.ceil(minimumLoadingAmount * 100) / 100);
    }

    return setMinAmountError('');
  };

  const paymentButtonClick = (amount: string) => () => {
    setAddMoneyToWallet(amount);
    checkAndSetError(amount);
  };

  const updateContentPrice = async (currencyISOCode: string) => {
    setCurrencyUpdating(true);
    const currencySymbol = getCurrencySymbol(currencyISOCode) || '₹';
    let updatedContentPrice = purchaseMode === 'PASS' ? userDetails.passPrice : userDetails.contentPrice;
    if (userDetails.contentCurrency !== currencyISOCode) {
      const updatedContentPriceResponse = await API.content.convertCurrency({
        from: userDetails.contentCurrency,
        to: currencyISOCode,
        amount: purchaseMode === 'PASS' ? userDetails.passPrice : userDetails.contentPrice,
      });
      updatedContentPrice = updatedContentPriceResponse.data;
    }

    if (currencyISOCode !== 'INR') setUsePaytm(false);
    const contentPriceDisplay = Math.ceil(updatedContentPrice * 100) / 100;

    //@ts-ignore
    const minAmount = minAmountForCurrencies[currencyISOCode];
    const balanceDeficit = updatedContentPrice;
    const minimumLoadingAmount = balanceDeficit >= minAmount ? balanceDeficit : minAmount;
    const paymentAmount = Math.ceil(minimumLoadingAmount * 100) / 100;
    setCurrency(currencyISOCode);
    setCurrencySymbol(currencySymbol);
    setBalanceDeficit(balanceDeficit);
    setContentPrice(currencySymbol + ' ' + parseFloat(contentPriceDisplay.toFixed(2)));
    setConvertedContentPrice(updatedContentPrice);
    setMinimumLoadingAmount(minimumLoadingAmount);
    setPaymentAmount(paymentAmount.toFixed(2));
    setCurrencyUpdating(false);
  };

  if (currencyLoading)
    return (
      <div>
        <LoadingCircle />
      </div>
    );

  return <></>;
};
