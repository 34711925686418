import axios, { AxiosResponse } from 'axios';
import store from 'app/store';
import qs from 'qs';
import { clearAuthDetails, setAuthDetails } from 'views/Auth/authSlice';
import { currencyType } from 'data/currencies';
import { GetJwtPayload, IsJWTExpired } from '../utils/jwt';

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_V2 = process.env.REACT_APP_API_URL_V2;
// env will be set to something like https://stage.tsbdev.co/api/v1

// Does not throw error on req failure
axios.defaults.validateStatus = function () {
  return true;
};

axios.interceptors.response.use(async function (response) {
  if (response.status === 401) {
    // Add more 401 exceptions here

    if (response.request.responseURL.includes('login-otp') || response.request.responseURL.includes('login-password'))
      return response;
    const userType = await store.getState().auth.userType;
    await store.dispatch(clearAuthDetails());
    if (
      userType === 'USER' &&
      window.location.href.includes('dashboard') &&
      window.location.href.includes('auto-login')
    )
      return response;
    if (userType === 'CLIENT') {
      window.location.href = '/client';
    } else if (userType === 'ADMIN') {
      window.location.href = '/admin';
    } else if (userType === 'USER' && window.location.href.includes('dashboard')) {
      window.location.href = '/';
    } else {
      window.location.reload();
    }
  }
  return response;
});

enum REST_METHODS {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
  PATCH = 'patch',
}

interface Dates {
  from?: Date;
  to?: Date;
}
interface PaginationParams extends Dates {
  pageNumber?: number;
  pageSize?: number;
}

interface ClientPaymentStats extends Dates {
  promotional?: string;
}

interface AggregatedConsumptionParams extends Dates {
  columns?: string[];
  slice?: string;
  clientId?: string;
}

interface CouponParams {
  activatesAt?: Date;
  deactivatesAt?: Date;
  code?: string;
  visibility?: string;
  maxValue?: number;
  clients?: string[] | null;
  contents?: string[] | null;
  newUsersOnly?: boolean;
  couponId?: string;
  reusable?: boolean;
  countInRevenue?: boolean;
}

interface TotalUsersParams extends Dates {
  slice?: string;
}

interface TopStoriesParams extends PaginationParams {
  sortBy: string;
}

interface transactionWithPagination extends Dates {
  status?: string;
  skip?: number;
  id?: string;
  category?: string;
  currency: string;
  phoneNumberOrEmail?: string;
  download?: boolean;
  clientId?: string;
}

interface SubscriptionStatsParams extends PaginationParams {
  clientId?: string;
}

interface ConcurrentReadersParams extends Dates {
  slice?: string;
  clientId?: string;
}

interface BifurcatedPricesParams extends Dates {
  clientId?: string;
}

interface GeoLocationParams extends Dates {
  clientId?: string;
  purchaseType?: string;
}

interface UTMParams extends Dates {
  clientId?: string;
  utmParameter?: string;
  contentIds?: string[];
  subscriptionIds?: string[];
  onlySubscriptions?: boolean;
  purchasesOnly?: boolean;
  utmValue?: string;
}

interface TotalWalletAdditionParams extends Dates {
  slice?: string;
}

function paginationArgsToParams({ from, to, pageNumber, pageSize }: PaginationParams) {
  return {
    ...(from ? { from } : {}),
    ...(to ? { to } : {}),
    pageNumber,
    pageSize,
  };
}

interface paginationWithClientParams extends Dates {
  pageNumber?: number;
  pageSize?: number;
  clientId?: string;
  contentIds?: string[];
  download?: boolean;
  type?: string;
}

const apiCaller = async (
  {
    method,
    params,
    url,
    data,
    headers,
    excludeAuthToken,
  }: {
    method: REST_METHODS;
    params?: Record<string, unknown>;
    url: string;
    data?: Record<string, unknown> | FormData;
    headers?: Record<string, unknown>;
    excludeAuthToken?: boolean;
  } = {
    method: REST_METHODS.GET,
    params: {},
    data: {},
    headers: {},
    url: '',
    excludeAuthToken: false,
  },
) => {
  let authDetails = await store.getState().auth;
  let accessToken = authDetails.token;
  const refreshToken = authDetails.refreshToken;

  const userType = authDetails.userType;
  if (userType === 'USER' && !excludeAuthToken && refreshToken && !IsJWTExpired(refreshToken)) {
    if (!accessToken || IsJWTExpired(accessToken)) {
      const newAuthDetailsResponse = await axios({
        method: 'GET',
        url: `${API_URL_V2}/authorization/refresh`,
        headers: { ...headers, Authorization: `Bearer ${refreshToken}` },
      });

      if (newAuthDetailsResponse.status === 200) {
        const payload = GetJwtPayload(newAuthDetailsResponse.data.accessToken);
        console.log(payload);
        await store.dispatch(
          setAuthDetails({
            phoneNumber: payload.phone,
            token: newAuthDetailsResponse.data.accessToken,
            userId: payload.sub,
            userType: 'USER',
            clientId: payload.iss,
            clientName: '',
            email: payload.email,
            sessionId: payload.jti,
            expiresAt: payload.exp,
            version: 0,
            clientDetails: [],
            refreshToken,
          }),
        );
        authDetails = await store.getState().auth;
        accessToken = authDetails.token;
      } else {
        await store.dispatch(clearAuthDetails());
      }
    }
  }

  if (accessToken && !excludeAuthToken) {
    headers = { ...headers, Authorization: `Bearer ${accessToken}` };
  }

  if (method === REST_METHODS.GET) {
    return axios.get(url, { params, headers });
  }
  return axios({
    method: method,
    url,
    data,
    headers,
    params,
  });
};

// !CONVENTION: All api methods start with a capital letter so as to not confuse with nesting
const API = {
  validators: {
    checkSuccessCode: (response: AxiosResponse): boolean => {
      return Math.floor(response.status / 100) === 2;
    },
    matchStatusCode: (response: AxiosResponse, statusCode: number): boolean => {
      return response.status === statusCode ? true : false;
    },
  },
  auth: {
    CreateAccount: ({
      phoneNumber,
      email,
      clientId,
      utmParameters,
      eventLocation,
      anonId,
      paywallId,
      paywallType,
      landingPageId,
      tierId,
    }: {
      phoneNumber?: string;
      email?: string;
      clientId?: string;
      utmParameters?: Record<string, any>;
      eventLocation?: string;
      anonId?: string;
      paywallId?: string;
      paywallType?: string;
      landingPageId?: string;
      tierId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/create-account`,
        data: {
          phoneNumber,
          email,
          clientId,
          utmParameters,
          eventLocation,
          anonId,
          paywallId,
          paywallType,
          landingPageId,
          tierId,
        },
      });
    },
    SendVerificationEmail: ({
      email,
      clientId,
      eventLocation,
      anonId,
      paywallId,
      paywallType,
    }: {
      email: string;
      clientId?: string;
      eventLocation?: string;
      anonId?: string;
      paywallId?: string;
      paywallType?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/send-verification-email`,
        data: { email, clientId, eventLocation, anonId, paywallId },
      });
    },
    AutoLogin: ({
      token,
      clientId,
      email,
      phoneNumber,
      loginChallengeId,
    }: {
      token: string;
      clientId: string;
      email?: string;
      phoneNumber?: string;
      loginChallengeId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/login-using-temp-token`,
        data: {
          ...(email && { email }),
          ...(loginChallengeId && { loginChallengeId }),
          clientId,
          tempAuthToken: token,
          ...(phoneNumber && { phoneNumber }),
        },
      });
    },
    GoogleLogin: ({
      token,
      sessionId,
      clientId,
      clientTierId,
      anonId,
      eventLocation,
      paywallId,
      paywallType,
    }: {
      token: string;
      sessionId?: string;
      clientId?: string;
      clientTierId?: string;
      anonId?: string;
      eventLocation?: string;
      paywallId?: string;
      paywallType?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/google-login`,
        data: { token, sessionId, clientId, clientTierId, anonId, eventLocation, paywallId, paywallType },
      });
    },
    SendOtp: ({
      phoneNumber,
      clientId,
      isClientLoginFlow,
      eventLocation,
      anonId,
      _id,
      paywallId,
      paywallType,
    }: {
      phoneNumber: string;
      clientId?: string;
      isClientLoginFlow?: boolean;
      eventLocation?: string;
      anonId?: string;
      _id?: string;
      paywallId?: string;
      paywallType?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/send-otp`,
        data: { phoneNumber, clientId, isClientLoginFlow, _id, eventLocation, anonId, paywallId, paywallType },
      });
    },
    LoginOtp: ({
      OTP,
      phoneNumber,
      email,
      clientId,
      promotionalOptIn,
      clientTierId,
      freeTrial,
      _id,
      eventLocation,
      anonId,
      firstTimeLogin,
      paywallId,
      landingPageId,
      tierId,
      paywallType,
    }: {
      OTP: string;
      phoneNumber?: string;
      email?: string;
      clientId?: string;
      promotionalOptIn?: boolean;
      clientTierId?: string;
      freeTrial?: boolean;
      _id?: string;
      eventLocation?: string;
      anonId?: string;
      firstTimeLogin?: boolean;
      paywallId?: string;
      landingPageId?: string;
      tierId?: string;
      paywallType?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: {
          phoneNumber,
          email,
          OTP,
          clientId,
          promotionalOptIn,
          clientTierId,
          freeTrial,
          _id,
          eventLocation,
          anonId,
          firstTimeLogin: firstTimeLogin ? 1 : 0,
          paywallId,
          landingPageId,
          tierId,
          paywallType,
        },
        url: `${API_URL}/auth/login-otp`,
      });
    },
    LoginPassword: ({ phoneNumber, password }: { phoneNumber: string; password: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: { phoneNumber, password },
        url: `${API_URL}/auth/login-password`,
      });
    },
    Logout: () => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/logout`,
      });
    },
    LoginWithAuhtorizationCode: ({ authorizationCode, clientId }: { authorizationCode: string; clientId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        params: {
          clientId,
        },
        url: `${API_URL_V2}/authorization/login`,
        headers: {
          Authorization: `Bearer ${authorizationCode}`,
        },
        excludeAuthToken: true,
      });
    },
    RefreshAccessToken: ({ refreshToken }: { refreshToken: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/authorization/refresh`,
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
        excludeAuthToken: true,
      });
    },
    LogoutAllSessions: () => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/logout-all-sessions`,
      });
    },
    ClientLogin: ({ email, password }: { email: string; password: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: { email, password },
        url: `${API_URL}/client/login`,
      });
    },
    AdminLogin: ({ email, password }: { email: string; password: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        data: { email, password },
        url: `${API_URL}/admin/login`,
      });
    },
    DetectCountry: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/auth/detect-country`,
      });
    },
    GetTierDetails: ({ tierId }: { tierId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/tier`,
        params: {
          tierId,
        },
      });
    },
    AddEmailNumber: ({
      phoneNumber,
      email,
      clientId,
      _id,
    }: {
      phoneNumber?: string;
      clientId?: string;
      _id: string;
      email?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/add-email-number`,
        data: { phoneNumber, clientId, _id, email },
      });
    },
  },
  content: {
    convertCurrency: ({ from, to, amount }: { from: string; to: string; amount: number }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content/convert-currency`,
        params: { from, to, amount },
      });
    },
    ContentDetails: ({
      clientContentId,
      clientId,
      sessionId,
      userDateTime,
      contentPurchaseType,
    }: {
      clientContentId: string;
      clientId: string;
      sessionId?: string;
      userDateTime?: Date;
      contentPurchaseType?: {
        digital: boolean;
        adFree: boolean;
      };
    }): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content`,
        params: {
          clientContentId,
          clientId,
          ...(sessionId && { sessionId }),
          userDateTime,
          contentPurchaseType: JSON.stringify(contentPurchaseType),
        },
        headers: {
          href: window.location.href,
        },
        excludeAuthToken: !sessionId ? true : false,
      });
    },
    GetViewDetails: ({ viewId }: { viewId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content/view`,
        params: { viewId },
      });
    },

    CreateView: ({
      clientId,
      clientContentId,
      sessionId,
      subscription,
      utmParameters,
      visitId,
      visitorId,
    }: {
      clientId: string;
      clientContentId: string;
      sessionId?: string;
      subscription?: boolean;
      utmParameters?: Record<string, any>;
      visitId?: string;
      visitorId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/content/view`,
        data: { clientId, clientContentId, subscription, sessionId, utmParameters, visitId, visitorId },
      });
    },
    UpdateView: ({
      viewId,
      action,
      userId,
      sessionId,
      paywallType,
      freeTrial,
      subscriptionDetails,
      paywallId,
    }: {
      viewId: string;
      action?: string;
      userId?: string;
      sessionId?: string;
      paywallType?: string;
      freeTrial?: boolean;
      paywallId?: string;
      subscriptionDetails?: Record<string, any>;
    }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/content/view`,
        data: { viewId, action, userId, paywallId, sessionId, paywallType, freeTrial, subscriptionDetails },
      });
    },

    AddCoupon: ({
      activatesAt,
      deactivatesAt,
      code,
      visibility,
      maxValue,
      clients,
      contents,
      newUsersOnly,
      reusable,
      countInRevenue,
    }: CouponParams) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/content/coupon`,
        data: {
          activatesAt,
          deactivatesAt,
          code,
          visibility,
          maxValue,
          clients,
          contents,
          newUsersOnly,
          reusable,
          countInRevenue,
        },
      });
    },

    UpdateCoupon: ({
      activatesAt,
      deactivatesAt,
      couponId,
      visibility,
      maxValue,
      clients,
      contents,
      newUsersOnly,
      reusable,
      countInRevenue,
    }: CouponParams) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/content/coupon/${couponId}`,
        data: {
          activatesAt,
          deactivatesAt,
          visibility,
          maxValue,
          clients,
          contents,
          newUsersOnly,
          reusable,
          countInRevenue,
        },
      });
    },

    AddPriceCategory: ({
      name,
      price,
      duration,
      currency,
      priceOverrides,
    }: {
      name: string;
      price: number;
      duration: number;
      currency: string;
      priceOverrides?: {
        country: {
          name: string;
          price: number;
          currency: string;
        }[];
      };
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/content/price-category`,
        data: { name, price, duration, currency, priceOverrides },
      });
    },

    UpdatePriceCategory: ({
      name,
      price,
      duration,
      currency,
      priceOverrides,
    }: {
      name: string;
      price: number;
      duration: number;
      currency: string;
      priceOverrides?: {
        country: {
          name: string;
          price: number;
          currency: string;
        }[];
      };
    }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/content/price-category`,
        data: { name, price, duration, currency, priceOverrides },
      });
    },

    DeletePriceCategory: ({ name }: { name: string }) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/content/price-category`,
        data: { name },
      });
    },

    FilterContentByTitle: ({ partialTitle, clientId }: { partialTitle?: string; clientId?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content/filter/title`,
        params: { partialTitle, clientId },
      });
    },

    AutocompleteSearchForIndepth: ({
      query,
      start,
      end,
      segmentedBy,
      isLive,
      top,
      author,
      section,
      source,
      device,
      userType,
      country,
      story,
      referrer,
      tag,
      category,
    }: {
      query?: string;
      start?: Date;
      end?: Date;
      segmentedBy: string;
      isLive: boolean;
      top: number;
      author?: string;
      section?: string;
      source?: string;
      device?: string;
      userType?: string;
      country?: string;
      story?: string;
      referrer?: string;
      tag?: string;
      category?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/indepth/autocomplete-search`,
        params: {
          ...(query && { q: query }),
          ...(start && { start }),
          ...(end && { end }),
          ...(segmentedBy && { segmentedBy }),
          author,
          section,
          source,
          device,
          userType,
          country,
          story,
          referrer,
          tag,
          category,
          isLive: isLive,
          top: top,
        },
      });
    },

    FetchPriceCategory: ({ clientId }: { clientId?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content/price-category`,
        params: { clientId },
      });
    },

    AccessCheck: ({
      clientContentId,
      clientId,
      userId,
      sessionId,
      templateId,
    }: {
      clientContentId: string;
      clientId: string;
      userId?: string;
      sessionId?: string;
      templateId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/content/access`,
        data: { clientContentId, clientId, userId, sessionId, templateId },
      });
    },

    DownloadConsumption: ({ consumptionId }: { consumptionId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content/download/${consumptionId}`,
      });
    },
  },
  user: {
    AccountDetails: ({ email, phoneNumber }: { email?: string; phoneNumber?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/user/info`,
        params: {
          email,
          phoneNumber,
        },
      });
    },
    UpdatePromotionalOptIn: ({ promotionalOptIn }: { promotionalOptIn: boolean }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/user/promotional-opt-in`,
        data: {
          promotionalOptIn,
        },
      });
    },
    setDetails: (data: {
      name?: string;
      secondaryEmail?: string;
      secondaryPhoneNumber?: string;
      dateOfBirth?: string;
      pincode?: string;
      address?: {
        apartment: string;
        area: string;
        city: string;
        state: string;
        country: string;
        landmark: string;
        pincode: string;
      };
      email?: string;
      phoneNumber?: string;
      clientId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/user/account`,
        data,
      });
    },
    AddSecondryDetails: ({
      email,
      phoneNumber,
      clientId,
    }: {
      email: string;
      phoneNumber: string;
      clientId: string;
    }) => {
      return apiCaller({
        url: `${API_URL}/auth/add-secondary-login`,
        method: REST_METHODS.POST,
        data: {
          email: email,
          phoneNumber: phoneNumber,
          clientId: clientId,
        },
      });
    },
    VerifySecondryDetails: ({
      email,
      phoneNumber,
      clientId,
      otp,
    }: {
      email: string;
      phoneNumber: string;
      clientId: string;
      otp: string;
    }) => {
      return apiCaller({
        url: `${API_URL}/auth/verify-secondary-login`,
        method: REST_METHODS.POST,
        data: {
          email: email,
          phoneNumber: phoneNumber,
          clientId: clientId,
          otp: otp,
        },
      });
    },

    SendOtpMergeNumber: ({
      email,
      phoneNumber,
      clientId,
    }: {
      email: string;
      phoneNumber: string;
      clientId: string;
    }) => {
      return apiCaller({
        url: `${API_URL}/auth/send-merge-otp`,
        method: REST_METHODS.POST,
        data: {
          email: email,
          phoneNumber: phoneNumber,
          clientId: clientId,
        },
      });
    },

    MergeUserAccount: ({ email, phoneNumber, otp }: { email: string; phoneNumber: string; otp: string }) => {
      return apiCaller({
        url: `${API_URL}/user/merge-account`,
        method: REST_METHODS.POST,
        data: {
          email: email,
          phoneNumber: phoneNumber,
          otp: otp,
        },
      });
    },

    Feedback: ({ formData }: { formData: FormData }) => {
      return apiCaller({
        url: `${API_URL}/user/feedback`,
        method: REST_METHODS.POST,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    Details: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/user`,
      });
    },

    TemporaryUser: (referenceId?: string) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/user/temporary-user`,
        params: { referenceId },
      });
    },
    validateSession: (sessionId: string) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/user/validate-sessionId`,
        params: { sessionId },
      });
    },
    UpdateLoggedInClients: ({ clientId, clientTierId }: { clientId: string; clientTierId?: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/update-logged-in-clients`,
        data: {
          clientId,
          clientTierId,
        },
      });
    },
    ContentDetails: ({
      clientContentId,
      clientId,
    }: {
      clientContentId: string;
      clientId: string;
    }): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content/user`,
        params: { clientContentId, clientId },
      });
    },

    latestPurchaseDetails: ({ clientId }: { clientId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/user/latest-subscription-details`,
        params: { clientId },
      });
    },

    AssociateAmpReaderId: ({ rid }: { rid: string }): Promise<AxiosResponse<{ message: string }>> => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/user/amp`,
        data: { rid },
      });
    },
    Purchase: ({
      clientContentId,
      clientId,
      price,
      title,
      url,
      currency,
      utmParameters,
      paywallId,
      journeyId,
      templateId,
      couponCode,
      userDateTime,
      type,
      contentPurchaseType,
      popupId,
      eventLocation,
      anonId,
      firstTimeLogin,
    }: {
      clientContentId: string;
      clientId: string;
      price: number;
      title?: string;
      journeyId?: any;
      paywallId?: any;
      templateId?: any;
      url?: string;
      currency?: string;
      utmParameters?: Record<string, any>;
      couponCode?: string;
      userDateTime?: Date;
      type: string;
      contentPurchaseType?: { digital: boolean; adFree: boolean };
      popupId?: string;
      eventLocation?: string;
      anonId?: string;
      firstTimeLogin?: boolean;
    }): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/content/purchase`,
        data: {
          clientContentId,
          clientId,
          price,
          title,
          url,
          currency,
          paywallId,
          journeyId,
          templateId,
          utmParameters,
          couponCode,
          type,
          contentType: contentPurchaseType,
          userDateTime,
          purchaseLocation: eventLocation,
          anonId,
          firstTimeLogin: firstTimeLogin ? 1 : 0,
          ...(popupId && { popupId }),
        },
      });
    },
    AuthorizeRenewal: ({
      price,
      currency,
      tierId,
      subscriptionId,
      duration,
      contentDetails,
    }: {
      price: number;
      currency: string;
      tierId: string;
      subscriptionId: string;
      duration: number;
      contentDetails?: any;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/authorize-renewal`,
        data: {
          price,
          currency,
          tierId,
          subscriptionId,
          duration,
          contentDetails,
        },
      });
    },
    postRenewal: ({
      clientId,
      subscriptionId,
      tierId,
      type,
      contentDetails,
      manuallyRenewed,
      couponCode,
      consumeFreeTrial,
      anonId,
    }: {
      clientId: string;
      subscriptionId: string;
      tierId: string;
      type: string;
      contentDetails: any;
      manuallyRenewed?: boolean;
      couponCode?: string;
      consumeFreeTrial?: boolean;
      anonId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/gateway/renewal`,
        data: {
          clientId,
          subscriptionId,
          tierId,
          type,
          contentDetails,
          manuallyRenewed,
          couponCode,
          consumeFreeTrial,
          anonId,
        },
      });
    },
    renewalStatus: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/payment/gateway/renewal/${id}/status`,
      });
    },
    transitionStatus: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/payment/gateway/transaction/${id}/status`,
      });
    },

    PurchaseSubscription: ({
      clientContentId,
      clientId,
      price,
      currency,
      utmParameters,
      offerIds,
      couponCode,
      tierId,
      subscriptionId,
      renew,
      transactionInfo,
      consumeFreeTrial,
      popupId,
      manuallyRenewed,
      rzpSubscriptionId,
      viewId,
    }: {
      clientContentId?: string;
      clientId: string;
      price?: number;
      currency?: string;
      utmParameters?: Record<string, any>;
      couponCode?: string;
      tierId: string;
      offerIds?: string[];
      subscriptionId: string;
      renew?: boolean;
      transactionInfo?: string;
      consumeFreeTrial?: boolean;
      popupId?: string;
      manuallyRenewed?: boolean;
      rzpSubscriptionId?: string;
      viewId?: string;
    }): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/purchase`,
        data: {
          clientContentId,
          clientId,
          price,
          currency,
          utmParameters,
          couponCode,
          tierId,
          offerIds,
          subscriptionId,
          renew,
          transactionInfo,
          consumeFreeTrial,
          ...(popupId && { popupId }),
          manuallyRenewed,
          rzpSubscriptionId,
          viewId,
        },
      });
    },
    PurchaseSubscriptionBundle: (referenceId: string): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/purchaseBundle`,
        data: { referenceId },
      });
    },
    CancelSubscription: ({ rzpSubscriptionId }: { rzpSubscriptionId: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/payment/gateway/renewal/cancel`,
        data: {
          rzpSubscriptionId,
        },
      });
    },
    setPassword: ({ password }: { password: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/user/set-password`,
        data: {
          password,
        },
      });
    },
    setSecondaryDetails: ({
      secondaryEmail,
      secondaryPhoneNumber,
    }: {
      secondaryEmail?: string;
      secondaryPhoneNumber?: string;
    }): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/user/secondary-details`,
        data: { secondaryEmail, secondaryPhoneNumber },
      });
    },
    TotalMoneyAdded: ({ from, to }: Dates) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/user/money-added`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      });
    },
    UserSubscriptions: ({ from, to }: Dates) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/user/subscriptions`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      });
    },

    stats: {
      Consumption: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/user/stats/consumption`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      PassesConsumption: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/user/stats/passes-consumption`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      ActivePasses: () =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/user/active-passes`,
        }),
      DownloadConsumption: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/user/stats/download-consumption`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      TotalConsumption: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/user/stats/aggregated-consumption`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      Transactions: (paginationParams: PaginationParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/user/stats/transactions`,
          params: paginationArgsToParams(paginationParams),
        }),
      Donations: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/user/donations`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
    },
    payment: {
      createPayment: ({
        amount,
        contentDetails,
        currency,
        donationId,
      }: {
        amount: number;
        contentDetails?: any;
        currency: string;
        donationId?: string;
      }): Promise<AxiosResponse<unknown>> => {
        return apiCaller({
          method: REST_METHODS.POST,
          url: `${API_URL}/payment`,
          data: {
            amount,
            contentDetails,
            currency,
            donationId,
          },
        });
      },
      createRzpPayment: ({
        amount,
        currency,
        contentDetails,
        donationId,
      }: {
        amount: number;
        currency: string;
        contentDetails?: Record<string, any>;
        donationId?: string;
      }) => {
        return apiCaller({
          method: REST_METHODS.POST,
          url: `${API_URL}/payment/razorpay`,
          data: {
            amount,
            contentDetails,
            currency,
            donationId,
          },
        });
      },
      pgIntegration: ({
        clientId,
        clientContentId,
        type,
        tierId,
        subscriptionId,
        referenceId,
        offerIds,
        viewId,
        anonId,
        purchaseLocation,
        consumeFreeTrial,
        manuallyRenewed,
        couponCode,
        paywallId,
        firstTimeLogin,
        landingPageId,
        utmParameters,
        popupId,
        contentType,
        userDateTime,
        contentDetails,
        paywallType,
      }: {
        clientId: string;
        clientContentId?: string;
        type?: string;
        tierId?: string;
        subscriptionId?: string;
        referenceId?: string;
        offerIds?: any;
        viewId?: string;
        anonId?: string;
        purchaseLocation?: string;
        consumeFreeTrial?: boolean;
        manuallyRenewed?: boolean;
        couponCode?: string;
        paywallId?: string;
        firstTimeLogin?: boolean;
        landingPageId?: string;
        utmParameters?: any;
        popupId?: string;
        contentType?: string;
        userDateTime?: string;
        contentDetails?: any;
        paywallType?: string;
      }) => {
        return apiCaller({
          method: REST_METHODS.POST,
          url: `${API_URL_V2}/payment/gateway/order`,
          data: {
            clientId,
            clientContentId,
            type,
            tierId,
            subscriptionId,
            referenceId,
            offerIds,
            viewId,
            anonId,
            purchaseLocation,
            consumeFreeTrial,
            manuallyRenewed,
            couponCode,
            paywallId,
            firstTimeLogin: firstTimeLogin ? 1 : 0,
            landingPageId,
            utmParameters,
            popupId,
            contentType,
            userDateTime,
            contentDetails,
            paywallType,
          },
        });
      },
      abortTransaction: ({ type, id, event }: { type: string; id: string; event: any }) => {
        return apiCaller({
          method: REST_METHODS.POST,
          url: `${API_URL_V2}/payment/abort`,
          data: {
            id,
            type,
            event,
          },
        });
      },
      getTransaction: ({
        transactionId,
      }: {
        transactionId: string;
      }): Promise<
        AxiosResponse<{
          transaction: { contentDetails: Record<string, any>; rzpResponse: string; purchasedSubscription?: boolean };
        }>
      > => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL_V2}/payment/transaction/${transactionId}`,
        });
      },
    },
    getLoginChallenge: (loginChallengeId: string): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/login-challenge/${loginChallengeId}`,
      });
    },
    updateLoginChallenge: (loginChallengeId: string, sessionId: string): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/login-challenge/${loginChallengeId}`,
        data: { sessionId },
      });
    },
  },
  donation: {
    GetDonation: ({ donationId }: { donationId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/donation`,
        params: {
          donationId,
        },
      });
    },
    UpdateDonation: ({
      donor,
      amount,
      currency,
      donationId,
    }: {
      donor: {
        name?: string;
        email?: string;
        message?: string;
      };
      amount: number;
      currency: string;
      donationId: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/donation/${donationId}`,
        data: {
          donor,
          amount,
          currency,
        },
      });
    },
    ConfirmDonation: ({ donationId }: { donationId: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/donation/confirm/${donationId}`,
      });
    },
  },
  client: {
    SkipGoals: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/goal/skip-updating-goal-this-month`,
      }),

    UpdateGoal: ({
      userGoal,
      payerGoal,
      revenueGoalAmount,
      revenueGoalCurrency,
      pageViewGoal,
    }: {
      userGoal: number;
      payerGoal: number;
      revenueGoalAmount: number;
      revenueGoalCurrency: string;
      pageViewGoal: any;
    }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/goal`,
        data: {
          userGoal,
          payerGoal,
          revenueGoalAmount,
          revenueGoalCurrency,
          pageViewGoal,
        },
      }),

    CreateGoal: ({
      userGoal,
      payerGoal,
      revenueGoalAmount,
      revenueGoalCurrency,
      pageView,
    }: {
      userGoal: number;
      payerGoal: number;
      revenueGoalAmount: number;
      revenueGoalCurrency: string;
      pageView: any;
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/goal`,
        data: {
          userGoal,
          payerGoal,
          revenueGoalAmount,
          revenueGoalCurrency,
          pageView,
        },
      }),

    GetGoal: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/goal`,
      }),

    GetPreviousData: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/goal/with-last-month-goal`,
      }),
    GetPaywallInfo: ({
      clientId,
      from,
      to,
      pageNumber,
      showRecords,
      search,
    }: {
      clientId: string;
      from: string;
      to: string;
      pageNumber: number;
      showRecords: number;
      search: string;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/paywall/list-paywall-config`,
        params: {
          ...(clientId && { clientId }),
          ...(from && { from }),
          ...(to && { to }),
          ...(pageNumber && { pageNumber }),
          ...(showRecords && { showRecords }),
          ...(search && { search }),
        },
      }),
    GetPaywallInfoForImg: ({ deviceType }: { deviceType: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/paywall/list-paywall-config?deviceType=${deviceType}`,
      }),
    GetPopupInfoForImg: ({ type }: { type: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/popup?type=${type}`,
      }),
    GetPaywallJourneys: ({
      pageNumber,
      pageSize,
      search,
      type,
    }: {
      pageNumber: number;
      pageSize: number;
      search?: string;
      type: string;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/journey/page`,
        params: {
          ...(search && { search }),
          ...(pageNumber && { pageNumber }),
          ...(type && { type }),
          showRecords: pageSize,
        },
      }),
    GetSingleUserJourneyPage: ({ id }: { id: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/journey/page/${id}`,
      }),
    CreateUserJourneyPage: ({ collectMainData }: { collectMainData: any }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/journey/page`,
        data: collectMainData,
      }),
    UpdateUserJourneyPage: ({ pageData, id }: { pageData: any; id: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/journey/page/${id}`,
        data: pageData,
      }),
    DeleteUserJourneyPage: ({ _id }: { _id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/journey/page/${_id}`,
      }),
    GetSegmentData: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/journey/segment`,
      }),
    CreateJourneySegment: ({ segmentData }: { segmentData: any }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/journey/segment`,
        data: segmentData,
      }),
    CalculateJourneySegment: ({ segmentData }: { segmentData: any }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/journey/segment/size`,
        data: segmentData,
      }),
    UpdateSegmentData: ({ id, segmentData }: { id: string; segmentData: any }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/journey/segment/${id}`,
        data: segmentData,
      }),
    DeleteSegmentData: ({ _id }: { _id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/journey/segment/${_id}`,
      }),
    AddCustomizationData: ({ clientId, formData }: { clientId: string; formData: any }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/paywall/create-paywall-config?clientId=${clientId}`,
        data: formData,
      }),
    UpdateCustomizationData: ({ formData, paywallId }: { formData: any; paywallId?: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/paywall/update-paywall-config/${paywallId}`,
        data: formData,
      }),

    BannerDetails: ({ page, pageSize, q }: { page: number; pageSize: number; q?: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/metering/banner`,
        params: {
          ...(q && { q }),
          ...(page && { page }),
          showRecords: pageSize,
        },
      }),
    BannerList: ({ deviceType }: { deviceType: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/metering/template`,
        params: {
          ...(deviceType && { deviceType }),
        },
      }),
    CreateBanner: ({ formData }: { formData: any }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/metering/banner`,
        data: formData,
      }),
    UpdateBanner: ({ formData, _id }: { formData: any; _id: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/metering/banner/${_id}`,
        data: formData,
      }),
    DeleteBanner: ({ _id }: { _id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/metering/banner/${_id}`,
      }),
    DuplicateBanner: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/metering/banner/${id}/copy`,
      });
    },
    GetTemplateInfo: ({
      paywallType,
      number_of_cta,
      displayType,
      deviceType,
    }: {
      paywallType: string;
      number_of_cta?: number | null;
      displayType?: string;
      deviceType?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/paywall/list-paywall-template`,
        params: {
          ...(paywallType && { paywallType }),
          ...(number_of_cta && { number_of_cta }),
          ...(displayType && { displayType }),
          ...(deviceType && { deviceType }),
        },
      });
    },
    UpdatePaywallInfo: ({ paywallId, state }: { paywallId: string; state: boolean }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/paywall/update-paywall-config/${paywallId}`,
        data: { enable: state },
      });
    },

    AccumulatedStatsPopUpStats: ({
      startDate,
      endDate,
      deviceType,
      clientId,
    }: {
      startDate?: Date;
      endDate?: Date;
      deviceType?: string;
      clientId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/popup-stats/accumulates`,
        params: {
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
          ...(deviceType && { deviceType }),
          ...(clientId && { clientId }),
        },
      });
    },

    UserViewTrendPopUpStats: ({
      start,
      end,
      type,
      segmentedBy,
      clientId,
    }: {
      start: Date;
      end: Date;
      type: string;
      segmentedBy: string;
      clientId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/popup-stats/user-view-trend`,
        params: {
          ...(start && { start }),
          ...(end && { end }),
          ...(type && { type }),
          ...(segmentedBy && { segmentedBy }),
          ...(clientId && { clientId }),
        },
      });
    },

    PopUpListStats: ({
      startDate,
      endDate,
      clientId,
      deviceType,
    }: {
      startDate: Date;
      endDate: Date;
      clientId?: string;
      deviceType: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/popup-stats/popup-list`,
        params: {
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
          ...(deviceType && { deviceType }),
          ...(clientId && { clientId }),
        },
      });
    },

    PopUpExpandSourceDropDownList: ({ start, end, popupId }: { start: string; end: string; popupId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/popup-stats/source-types`,
        params: {
          ...(start && { start }),
          ...(end && { end }),
          ...(popupId && { popupId }),
        },
      });
    },

    PopUpExpandSourceTrend: ({
      start,
      end,
      popupId,
      sources,
      referrers,
    }: {
      start: string;
      end: string;
      popupId: string;
      sources: string;
      referrers: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/popup-stats/segmented-source`,
        params: {
          start,
          end,
          popupId,
          sources,
          referrers,
        },
      });
    },

    RevenueDataForOverview: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/revenue`,
      }),
    RevenueDataForExpanded: ({ from, to }: { from: string; to: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/expanded/revenue`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      }),
    PayersDataForOverview: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/payers`,
      }),
    PayersDataForExpanded: ({ from, to }: { from: string; to: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/expanded/payers`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      }),
    ActivePayingUsers: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/active-paying-users`,
      }),
    ActivePayingUsersExpanded: ({ from, to }: { from: string; to: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/expanded/active-paying-users`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      }),

    UserDataForOverview: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/users`,
      }),
    UserDataForExpanded: ({ from, to }: { from: string; to: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/expanded/users`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      }),
    cptForSummary: ({ type, from, to }: { type: string; from: Date; to: Date }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/cpt`,
        params: {
          ...(type && { type }),
          ...(from && { from }),
          ...(to && { to }),
        },
      }),
    rptForSummary: ({ type }: { type: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/rpt`,
        params: {
          ...(type && { type }),
        },
      }),
    topSelling: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/best-seller`,
      }),
    eightfeOneR: ({ duration }: { duration: number }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/8f1r`,
        params: {
          ...(duration && { duration }),
        },
      }),
    eightfOneDataExpanded: ({ duration }: { duration: number }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/8f1r/expanded`,
        params: {
          ...(duration && { duration }),
        },
      }),

    RealTimeUser: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/real-time-user`,
      }),
    PremiumContent: ({ from, to }: { from: Date; to: Date }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/premium-content`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      }),

    TopContentsExpanded: ({ from, to, type, order }: { from: Date; to: Date; type: string; order: boolean }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/expanded/top-contents`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
          ...(type && { type }),
          ...(type && { order }),
        },
      }),

    TopContents: ({ from, to, type, order }: { from: Date; to: Date; type: string; order: boolean }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/top-contents`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
          ...(type && { type }),
          ...(type && { order }),
        },
      }),

    StopRateAnalysis: ({ type, from, to }: { type: string; from: Date; to: Date }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/stop-rate-analysis`,
        params: {
          ...(type && { type }),
          ...(from && { from }),
          ...(to && { to }),
        },
      }),

    ExpandedStopRate: ({ from, to, type }: { from: string; to: string; type: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/stop-rate-analysis`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
          ...(type && { type }),
        },
      }),
    RevenueTrendnalysis: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/revenue-trend-analysis`,
      }),
    RevenueTrendnalysisExpanded: ({ from, to }: { from: string; to: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/expanded/revenue-trend-analysis`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      }),
    UserRetention: ({ type, from, to }: { type: string; from: Date; to: Date }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/user-retention`,
        params: {
          ...(type && { type }),
          ...(from && { from }),
          ...(to && { to }),
        },
      }),

    UserRetentionExpanded: ({ from, to }: { from: string; to: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/expanded/user-retention`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      }),

    TrafficSource: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/traffic-source`,
      }),
    AverageTransaction: ({ from, to, type }: { from: any; to: any; type: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/average-transaction`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
          ...(type && { type }),
        },
      }),
    UserChurn: ({ type }: { type: any }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/user-churn`,
        params: {
          ...(type && { type }),
        },
      }),
    SubscriptionChurn: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/churn-prediction`,
      }),
    RfmScore: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/rfm-score`,
      }),

    UserVisits: ({
      start,
      end,
      isLive,
      author,
      section,
      source,
      device,
      userType,
      country,
      story,
      referrer,
      tag,
      category,
      pageType,
    }: {
      start?: Date;
      end?: Date;
      isLive?: boolean;
      author?: string;
      section?: string;
      source?: string;
      device?: string;
      userType?: string;
      country?: string;
      story?: string;
      referrer?: string;
      tag?: string;
      category?: string;
      pageType?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/indepth/user-trend-graph`,
        params: {
          ...(start && { start }),
          ...(end && { end }),
          isLive: isLive,
          author,
          section,
          source,
          device,
          userType,
          country,
          story,
          referrer,
          tag,
          category,
          pageType,
        },
      }),

    InDepthDropdown: ({
      start,
      end,
      isLive,
      author,
      section,
      source,
      device,
      userType,
      country,
      story,
      referrer,
      tag,
      category,
      pageType,
    }: {
      start: Date;
      end: Date;
      isLive?: boolean;
      author?: string;
      section?: string;
      source?: string;
      device?: string;
      userType?: string;
      country?: string;
      story?: string;
      referrer?: string;
      tag?: string;
      category?: string;
      pageType?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/indepth/dropdown-options`,
        params: {
          ...(start && { start }),
          ...(end && { end }),
          isLive: isLive,
          author,
          section,
          source,
          device,
          userType,
          country,
          story,
          referrer,
          tag,
          category,
          pageType,
        },
      }),

    MetricIndepths: ({
      start,
      end,
      includeMetrics,
      isLive,
      author,
      section,
      source,
      device,
      userType,
      country,
      story,
      referrer,
      tag,
      category,
      pageType,
    }: {
      start: Date;
      end: Date;
      includeMetrics: string;
      isLive?: boolean;
      author?: string;
      section?: string;
      source?: string;
      device?: string;
      userType?: string;
      country?: string;
      story?: string;
      referrer?: string;
      tag?: string;
      category?: string;
      pageType?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/indepth/metrics`,
        params: {
          ...(start && { start }),
          ...(end && { end }),
          ...(includeMetrics && { includeMetrics }),
          isLive: isLive,
          author,
          section,
          source,
          device,
          userType,
          country,
          story,
          referrer,
          tag,
          category,
          pageType,
        },
      }),

    IndepthsTable: ({
      includeMetrics,
      start,
      end,
      segmentedBy,
      page,
      pageSize,
      isLive,
      author,
      section,
      source,
      userType,
      device,
      download,
      searchBy,
      story,
      country,
      referrer,
      tag,
      category,
      segmentId,
      extendedSegmentedBy,
      pageType,
    }: {
      includeMetrics?: string;
      start: Date;
      end: Date;
      segmentedBy?: string;
      userType?: string;
      page?: number;
      pageSize?: number;
      isLive?: boolean;
      author?: string;
      country?: string;
      section?: string;
      source?: string;
      device?: string;
      download?: boolean;
      searchBy?: string;
      story?: string;
      referrer?: string;
      tag?: string;
      category?: string;
      segmentId?: string;
      extendedSegmentedBy?: string;
      pageType?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/indepth/segment-metrics-table`,
        params: {
          ...(start && { start }),
          ...(end && { end }),
          ...(includeMetrics && { includeMetrics }),
          ...(segmentedBy && { segmentedBy }),
          ...(page && { page }),
          ...(pageSize && { pageSize }),
          isLive: isLive,
          author,
          section,
          source,
          device,
          userType,
          country,
          story,
          referrer,
          tag,
          category,
          ...(searchBy && { searchBy: searchBy }),
          download: download,
          segmentId: segmentId,
          extendedSegmentedBy: extendedSegmentedBy,
          pageType,
        },
      }),

    // EXTENDED INDEPTH

    extendedGraph: ({
      segmentedBy,
      segmentId,
      startDate,
      endDate,
      basedOn,
      isLive,
    }: {
      segmentedBy?: string;
      segmentId?: any;
      startDate?: Date;
      endDate?: Date;
      basedOn?: string;
      isLive?: boolean;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/indepth/expanded-user-view-trend`,
        params: {
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
          ...(segmentedBy && { segmentedBy }),
          ...(segmentId && { segmentId }),
          ...(basedOn && { basedOn }),
          ...(isLive && { isLive }),
        },
      }),

    ExtendedDropdown: ({
      segmentedBy,
      segmentId,
      start,
      end,
    }: {
      segmentedBy?: string;
      segmentId?: any;
      start?: Date;
      end?: Date;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/analytics/indepth/segment-metadata`,
        params: {
          ...(start && { start }),
          ...(end && { end }),
          ...(segmentedBy && { segmentedBy }),
          ...(segmentId && { segmentId }),
        },
      }),

    DeletePaywallInfo: ({ _id }: { _id: string }) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/paywall/paywall-config/${_id}`,
      });
    },
    DuplicatePaywall: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/paywall/${id}/copy`,
      });
    },

    CreateClient: ({
      name,
      address,
      pincode,
      email,
      password,
      gstNumber,
      panNumber,
      commission,
      internationalCommission,
      accountNumber,
      bankName,
      branchName,
      ifscCode,
      designation,
      pocName,
      contentDefaults,
      generateInvoices,
      iconUrl,
      taxType,
      hsn,
      buttonStyle,
      subscriptionText,
      subscriptionTitle,
      crossButtonClickUrl,
      homePageUrl,
      senderEmail,
      subscriptionDetails,
    }: {
      name: string;
      address: string;
      pincode: string;
      email: string;
      password: string;
      commission: number;
      internationalCommission: number;
      gstNumber: string;
      panNumber: string;
      accountNumber: string;
      bankName: string;
      branchName: string;
      ifscCode: string;
      designation: string;
      pocName: string;
      generateInvoices: boolean;
      iconUrl: string;
      taxType: string;
      hsn: string;
      contentDefaults: {
        price: number;
        duration: number;
        currency: string;
        contentType: string;
        priceOverrides?: {
          country: {
            name: string;
            price: number;
            currency: string;
          }[];
        };
      };
      buttonStyle?: any;
      subscriptionText?: string;
      subscriptionTitle?: string;
      crossButtonClickUrl?: string;
      homePageUrl: string;
      senderEmail?: string;
      subscriptionDetails: {
        commission: {
          physical: number;
          digital: number;
        };
        internationalCommission: {
          physical: number;
          digital: number;
        };
      };
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/`,
        data: {
          name,
          address,
          pincode,
          email,
          password,
          gstNumber,
          panNumber,
          accountNumber,
          commission,
          internationalCommission,
          bankName,
          branchName,
          ifscCode,
          designation,
          pocName,
          contentDefaults,
          generateInvoices,
          iconUrl,
          taxType,
          hsn,
          buttonStyle,
          paywallCustomization: {
            subscriptionText,
            subscriptionTitle,
            crossButtonClickUrl,
          },
          subscriptionDetails,
          homePageUrl,
          senderEmail,
        },
      }),
    UpdatePrice: ({
      price,
      duration,
      currency,
      contentType,
      priceOverrides,
    }: {
      price: number;
      duration: number;
      currency: string;
      contentType: string;
      priceOverrides?: {
        country: {
          name: string;
          price: number;
          currency: string;
        }[];
      };
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/update-content-defaults`,
        data: {
          price,
          duration,
          currency,
          contentType,
          priceOverrides,
        },
      }),

    UpdatePaywallCustomization: ({ formData }: { formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/update-paywall-customization`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
    uploadPaywallCustomizationImage: ({ paywallId, formData }: { paywallId: string; formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/paywall/upload-paywall-preview/${paywallId}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
    paywallConfig: ({ paywallId, clientid }: { paywallId: string; clientid: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/paywall/paywall-config/${clientid}`,
        params: {
          paywallId: paywallId,
        },
      }),
    bannerConfig: ({ paywallId }: { paywallId: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/metering/banner/${paywallId}`,
      }),

    transactionRevenueData: ({ from, type, clientId }: { from: number; type: string; clientId?: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/transaction-revenue-data`,
        params: {
          ...(from && { from }),
          ...(type && { type }),
          ...(clientId && { clientId }),
        },
      }),
    FilterTransactionsData: ({
      status,
      skip,
      from,
      to,
      id,
      category,
      currency,
      phoneNumberOrEmail,
      download,
      clientId,
    }: transactionWithPagination) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/transaction-data/filter`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
          ...(status && { status }),
          ...(skip && { skip }),
          ...(id && { id }),
          ...(category && { category }),
          ...(currency && { currency }),
          ...(phoneNumberOrEmail && { phoneNumberOrEmail }),
          ...(download && { download }),
          ...(clientId && { clientId }),
        },
      }),
    TransactionsDataById: ({ id }: { id: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/transaction/${id}`,
      }),

    UpdateInternalUsers: ({ internalUsers }: { internalUsers: string }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/internal-user`,
        data: {
          internalUsers,
        },
      }),
    GetInternalUsers: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/internal-user`,
      }),
    GetWebhooksEventList: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/webhooks/events`,
      }),
    GetWebhooksList: ({ pageNumber, pageSize, q }: { pageNumber: number; pageSize: number; q?: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/webhooks?page=${pageNumber}&pageSize=${pageSize}&q=${q}`,
      }),
    UpdateWebHooks: ({ pageData, id }: { pageData: any; id: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/webhooks/${id}`,
        data: pageData,
      }),
    DeleteNewWebhooks: ({ _id }: { _id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/webhooks/${_id}`,
      }),
    CreateWebhooks: ({ webhooksData }: { webhooksData: any }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/webhooks`,
        data: webhooksData,
      }),
    UpdateWebhook: ({ enabled, url, type }: { enabled: boolean; url: string; type: string }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/webhook`,
        data: {
          enabled,
          url,
          type,
        },
      }),
    FetchWebhooks: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/webhooks`,
      }),
    PaywallCustomization: ({ clientId }: { clientId?: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/paywall-customization`,
        params: {
          ...(clientId && { clientId }),
        },
      }),
    GetBrandingLogo: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/payment/gateway/branding`,
      }),
    UpdateLogo: ({ formData }: { formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/payment/gateway/branding`,
        data: formData,
      }),

    EditClient: ({
      name,
      address,
      pincode,
      email,
      enableInDepth,
      gstNumber,
      panNumber,
      accountNumber,
      bankName,
      branchName,
      commission,
      internationalCommission,
      ifscCode,
      designation,
      pocName,
      buttonStyle,
      subscriptionText,
      subscriptionTitle,
      crossButtonClickUrl,
      contentDefaults,
      generateInvoices,
      iconUrl,
      taxType,
      hsn,
      subscriptionDetails,
      enableMicroPricing,
      homePageUrl,
      senderEmail,
    }: {
      name: string;
      address: string;
      pincode: string;
      enableInDepth: boolean;
      email: string;
      gstNumber: string;
      panNumber: string;
      accountNumber: string;
      bankName: string;
      branchName: string;
      ifscCode: string;
      designation: string;
      pocName: string;
      buttonStyle: any;
      subscriptionText: string;
      subscriptionTitle: string;
      generateInvoices: boolean;
      iconUrl: string;
      taxType: string;
      hsn: string;
      enableMicroPricing?: boolean;
      commission: number;
      internationalCommission: number;
      contentDefaults: {
        price: number;
        duration: number;
        currency: string;
        contentType: string;
        priceOverrides?: {
          country: {
            name: string;
            price: number;
            currency: string;
          }[];
        };
      };
      crossButtonClickUrl: string;
      subscriptionDetails: {
        commission: {
          physical: number;
          digital: number;
        };
        internationalCommission: {
          physical: number;
          digital: number;
        };
        pageMessage: string;
        subscriptionEnabled: boolean;
      };
      homePageUrl: string;
      senderEmail?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/edit`,
        data: {
          name,
          address,
          pincode,
          email,
          enableInDepth,
          gstNumber,
          panNumber,
          accountNumber,
          commission,
          internationalCommission,
          bankName,
          branchName,
          ifscCode,
          designation,
          pocName,
          buttonStyle,
          generateInvoices,
          iconUrl,
          paywallCustomization: {
            subscriptionText,
            subscriptionTitle,
            crossButtonClickUrl,
          },
          contentDefaults,
          taxType,
          hsn,
          subscriptionDetails,
          enableMicroPricing,
          homePageUrl,
          senderEmail,
        },
      }),
    UpdatePassword: ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/update-password`,
        data: {
          currentPassword,
          newPassword,
        },
      }),
    ResetPassword: ({ clientId, password }: { clientId: string; password: string }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/reset-password`,
        data: {
          clientId,
          password,
        },
      }),
    ClientDetails: ({ clientId }: { clientId?: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client`,
        params: {
          ...(clientId && { clientId }),
        },
      }),

    ClientLogo: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/logos`,
      }),

    GetPasses: ({ clientId }: { clientId?: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/passes`,
        params: {
          ...(clientId && { clientId }),
        },
      }),

    ClientNewUsersCsvs: ({ from, to }: Dates) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/new-users-csv`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
        },
      }),
    Invoices: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/invoices`,
      }),
    SubmitInvoice: ({ invoiceId }: { invoiceId: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/invoice/submit`,
        data: {
          invoiceId,
        },
      }),
    SetInvoiceStatus: ({ invoiceId, status, settledDate }: { invoiceId: string; status: string; settledDate?: Date }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/invoice/status`,
        data: {
          invoiceId,
          status,
          settledDate,
        },
      }),
    PendingInvoices: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/invoice?status=PENDING`,
      }),
    UserDetails: ({ userRecogniser }: { userRecogniser: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/user-details/${userRecogniser}`,
      }),
    UpdatePasses: ({
      passes,
      adFreeRedirectUrl,
      clientId,
    }: {
      passes: {
        price: number;
        currency: string;
        enabled: boolean;
        duration: number;
        title: string;
        priority: number;
        range?: {
          startDay: string;
          endDay: string;
          startTime: string;
          endTime: string;
        };
        priceOverrides?: {
          country: {
            name: string;
            price: number;
            currency: string;
          }[];
        };
      }[];
      adFreeRedirectUrl: string;
      clientId?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/passes/`,
        data: { passes, adFreeRedirectUrl, clientId },
      }),
    GetSubscriptionSettings: ({ clientId }: { clientId: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/settings`,
        params: {
          ...(clientId && { clientId }),
        },
      }),
    RedirectUserToContent: ({
      clientId,
      clientContentId,
      pageType,
    }: {
      clientId: string;
      clientContentId: string;
      pageType?: string;
    }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/redirect-to-content`,
        params: {
          ...(clientId && { clientId }),
          ...(clientContentId && { clientContentId }),
          ...(pageType && { pageType }),
        },
      }),
    UpdateSubscriptionSettings: ({ formData }: { formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/update-settings`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
    RemoveImageFromSubscriptionSettings: ({ imageType }: { imageType: string }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/remove-image`,
        data: {
          imageType,
        },
      }),
    CreatePopUp: ({ formData }: { formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/popup`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
    EditPopUp: ({ formData, popUpId }: { formData: FormData; popUpId?: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/popup/${popUpId}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
    DeletePopUp: ({ _id }: { _id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/client/popup/${_id}`,
      }),
    DuplicatePopup: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/popup/${id}/copy`,
      });
    },
    PopUpDetails: ({ page, pageSize, q }: { page: number; pageSize: number; q: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/popup`,
        params: {
          ...(page && { page }),
          ...(pageSize && { pageSize }),
          ...(q && { q }),
        },
      }),

    AddStoryForRecommendation: ({ formData }: { formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/recommended-content`,
        data: formData,
      }),
    EditStoryForRecommendation: ({ formData, id }: { formData?: FormData; id: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/recommended-content/${id}`,
        data: formData,
      }),

    RecommendedContent: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/recommended-content`,
      }),
    DeleteStory: ({ id }: { id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/client/recommended-content/${id}`,
      }),

    AddUserJourney: (userJourney: {
      enabled: boolean;
      title: string;
      activationConditions: { condition: string; value: string }[];
      cohorts: string[];
      result: {
        action: string;
        value: string;
      }[];
      _id: undefined;
      activationFrequency: string;
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/user-journey`,
        data: userJourney,
      }),
    GetActionDetailsForJourney: ({ type, deviceType }: { type: string; deviceType: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/journey/action?type=${type}&deviceType=${deviceType}`,
      }),
    EditUserJourney: (userJourney: {
      enabled: boolean;
      title: string;
      _id: string;
      activationConditions: { condition: string; value: string }[];
      cohorts: string[];
      result: {
        action: string;
        value: string;
      }[];
      activationFrequency: string;
    }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/user-journey/${userJourney._id}`,
        data: userJourney,
      }),
    GetRecommendationSettings: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/recommendation-settings`,
      }),
    ReloadAutomatedRecommendationsImages: () =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/recommendations/reload-images`,
      }),
    UpdateRecommendationSettings: ({
      desktopSettings,
      mobileSettings,
      manualEnabled,
      automatedEnabled,
      window,
      automaticDisplayCount,
      contentDisplayType,
    }: {
      manualEnabled?: boolean;
      automatedEnabled?: boolean;
      window?: number;
      automaticDisplayCount?: number;
      contentDisplayType?: string;
      desktopSettings?: {
        displayTitle: boolean;
        displayThumbnail: boolean;
        autoScroll: boolean;
        title: string;
        variant: string;
        variantPosition: string;
      };
      mobileSettings?: {
        displayTitle: boolean;
        displayThumbnail: boolean;
        autoScroll: boolean;
        title: string;
        variant: string;
        variantPosition: string;
      };
    }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/recommendation-settings`,
        data: {
          manualEnabled,
          automatedEnabled,
          window,
          automaticDisplayCount,
          desktopSettings,
          mobileSettings,
          contentDisplayType,
        },
      }),

    GetSubscriptions: ({ clientId, migrated }: { clientId?: string; migrated?: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription`,
        params: {
          ...(clientId && { clientId }),
          ...(migrated && { migrated }),
        },
      }),
    DeleteSubscription: ({ _id }: { _id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/subscription/${_id}`,
      }),

    bundledSubscriptionSettings: ({ formData }: { formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/bundled-subscription-settings`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),

    GetBundledSubscriptionSettings: ({ clientId }: { clientId?: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/bundled-subscription-settings`,
        params: {
          ...(clientId && { clientId }),
        },
      }),
    GetOffers: ({ clientId }: { clientId: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/offers`,
        params: {
          ...(clientId && { clientId }),
        },
      }),
    CreateOffer: ({ formData }: { formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/offer`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
    EditOffer: ({ formData, offerId }: { formData: FormData; offerId: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/subscription/offer/${offerId}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),
    DeleteOffer: ({ _id }: { _id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/subscription/offer/${_id}`,
      }),
    CreateSubscription: ({ formData }: { formData: FormData }) =>
      apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),

    EditSubscription: ({ formData, subscriptionId }: { formData: FormData; subscriptionId?: string }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/subscription/${subscriptionId}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }),

    DownloadCoupons: ({ subscriptionId }: { subscriptionId: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/coupon-codes`,
        params: {
          ...(subscriptionId && { subscriptionId }),
        },
      }),
    stats: {
      ItemizedTransactions: ({
        contentIds,
        from,
        to,
        pageNumber,
        pageSize,
        clientId,
        download,
        type,
      }: paginationWithClientParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/itemized-transactions?${qs.stringify({
            contentIds,
            from,
            to,
            pageNumber,
            pageSize,
            clientId,
            download,
            ...(type && { type }),
          })}`,
        }),

      PurchasesByCountry: ({ from, to, purchaseType }: GeoLocationParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/purchases-by-country`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(purchaseType && { purchaseType }),
          },
        }),
      UTMParameterStats: ({
        contentIds,
        from,
        to,
        utmParameter,
        clientId,
        subscriptionIds,
        onlySubscriptions,
        purchasesOnly,
        utmValue,
      }: UTMParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/utm-parameter-stats?${qs.stringify({
            contentIds,
            from,
            to,
            utmParameter,
            clientId,
            subscriptionIds,
            onlySubscriptions,
            purchasesOnly,
            utmValue,
          })}`,
        }),
      PassStats: ({ from, to, slice, clientId }: ConcurrentReadersParams) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/purchased-passes`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(slice && { slice }),
            ...(clientId && { clientId }),
          },
        });
      },
      NewUsers: ({ from, to }: Dates) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/new-users`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        });
      },
      Payments: ({ from, to, promotional }: ClientPaymentStats) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/payments`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(promotional && { promotional }),
          },
        });
      },
      UsersByCountry: ({ from, to }: BifurcatedPricesParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/users-by-country`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      Aggregated: ({ from, to, columns, slice }: AggregatedConsumptionParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/aggregated`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            columns,
            ...(slice && { slice }),
          },
        }),
      TopContents: ({ from, to, pageNumber, pageSize, clientId }: paginationWithClientParams, sortBy: string) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/top-contents`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            sortBy,
            ...(pageNumber && { pageNumber }),
            ...(pageSize && { pageSize }),
            ...(clientId && { clientId }),
          },
        }),
      TopUsers: ({ from, to, clientId }: BifurcatedPricesParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/top-users`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(clientId && { clientId }),
          },
        }),
      ConcurrentReaders: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/concurrent-readers`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      ViewsByOS: () =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/users/os`,
        }),

      ContentStats: ({ contentIds, from, to, pageNumber, pageSize, clientId }: paginationWithClientParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/content/stats?${qs.stringify({
            contentIds,
            from,
            to,
            pageNumber,
            pageSize,
            clientId,
          })}`,
        }),
      TagsStats: ({ contentIds, from, to, pageNumber, pageSize, clientId }: paginationWithClientParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/tags/purchases?${qs.stringify({
            contentIds,
            from,
            to,
            pageNumber,
            pageSize,
            clientId,
          })}`,
        }),
      SubscriberStats: ({
        from,
        to,
        clientId,
        subscriptionId,
        download,
        type,
      }: {
        from?: Date;
        to?: Date;
        clientId?: string;
        subscriptionId?: string;
        download?: boolean;
        type?: string;
      }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/subscribers?${qs.stringify({
            from,
            to,
            clientId,
            subscriptionId,
            download,
            type,
          })}`,
        }),

      subscriptionStats: ({
        from,
        to,
        slice,
        subscriptionId,
      }: {
        from?: Date;
        to?: Date;
        slice?: string;
        subscriptionId?: string;
      }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/subscription/stats/aggregated?${qs.stringify({
            from,
            to,
            slice,
            subscriptionId,
          })}`,
        }),
      userStatistics: ({ timeRange }: { timeRange: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/users?${qs.stringify({
            timeRange,
          })}`,
        }),

      ConversionStatistics: ({ timeRange }: { timeRange: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/conversion-graph?${qs.stringify({
            timeRange,
          })}`,
        }),
      RevenueAndGMVStatistics: ({ from }: { from: Date }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/revenue`,
          params: {
            ...(from && { from }),
          },
        }),
      viewsByDevice: () =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/users/device`,
        }),
      UserActivity: () =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/user-activity`,
        }),
      purchaseStatistics: ({ timeRange }: { timeRange: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/purchases`,
          params: {
            ...(timeRange && { timeRange }),
          },
        }),
      payerStatistics: ({ timeRange }: { timeRange: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/payers`,
          params: {
            ...(timeRange && { timeRange }),
          },
        }),
      BifurcatedPrices: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/bifurcated-transactions`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      UserCounts: ({ clientId, from, to }: { clientId?: string; from?: Date; to?: Date }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/user-count`,
          params: {
            ...(clientId && { clientId }),
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      InactiveUsersDetails: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/inactive-users`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      UserDetails: () =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/user-details`,
        }),
      PayerDetails: () =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/payer-details`,
        }),
      PopupStats: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/popup-view`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
    },

    Feedback: ({ formData }: { formData: FormData }) => {
      return apiCaller({
        url: `${API_URL}/client/feedback`,
        method: REST_METHODS.POST,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },

    GetDownloadableContents: ({ clientId }: { clientId?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content/downloadable-content`,
        params: { clientId },
      });
    },

    AddNewMagazine: ({ formData }: { formData: FormData }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/content/add-pdf`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    EditMagazine: ({ formData, _id }: { formData: FormData; _id: string }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/content/edit-pdf/${_id}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    ViewPDF: ({ clientId, contentId }: { clientId?: string; contentId: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/content/view-pdf`,
        data: { clientId, contentId },
      });
    },

    AddNewLandingPage: ({ formData }: { formData: FormData }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/landing-page`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    GetLandingPages: ({ clientId }: { clientId?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/landing-pages`,
        params: { clientId },
      });
    },

    EditLandingPage: ({ formData, _id }: { formData: FormData; _id: string }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/subscription/landing-page/${_id}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    CreateCampaign: ({
      pathURL,
      status,
      landingPageId,
      defaultCheck,
    }: {
      pathURL: string;
      status: boolean;
      landingPageId: string;
      defaultCheck: boolean;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/campaign`,
        data: { pathURL, status, landingPageId, defaultCheck },
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },

    GetCampaigns: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/campaigns`,
      });
    },

    EditCampaign: ({
      pathURL,
      campaignId,
      status,
      defaultCheck,
      landingPageId,
    }: {
      pathURL?: string;
      campaignId: string;
      status?: boolean;
      defaultCheck?: boolean;
      landingPageId?: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/subscription/campaign/${campaignId}`,
        data: { pathURL, status, defaultCheck, landingPageId },
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    DeleteSubsLandingPage: ({ _id }: { _id: string }) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/subscription/landing-pages/${_id}`,
      });
    },

    DeleteCampaign: ({ _id }: { _id: string }) => {
      return apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/subscription/campaign/${_id}`,
      });
    },

    SendEmailForVerification: ({ name, email }: { name?: string; email?: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/sendVerificationMail`,
        data: { name, email },
      });
    },
    ValidateClientEmailandMobile: ({ clientId }: { clientId: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/validateEmailAndMobile/${clientId}`,
      });
    },
    SendMobileOtp: ({ number, clientId }: { number: string; clientId?: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/sendMobileOtp`,
        data: { number, clientId },
      });
    },
    VerifyMobileOtp: ({ number, clientId, otp }: { number: string; clientId?: string; otp: string }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/verifyMobileOtp`,
        data: { number, clientId, otp },
      });
    },
    UpdateLoginCustomization: ({ postbody }: { postbody: any }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/client/update-login-customization`,
        data: postbody,
      });
    },
    GetLoginCustomization: ({ clientId }: { clientId?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/client/login-customization`,
        params: {
          clientId,
        },
      });
    },
    UpdateLoginSettings: (formData: FormData) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/update-login-customization-settings`,
        data: formData,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    UpdateLoginCustomizationSettings: ({
      uiConfig,
      page,
    }: {
      uiConfig: string;
      page: 'signIn' | 'userDetails' | 'otp';
    }) => {
      return apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/client/update-login-customization-ui`,
        data: {
          uiConfig,
          page,
        },
      });
    },

    CreateEmbeddedSubs: ({
      title,
      subscriptions,
    }: {
      title: string;
      subscriptions: {
        subscriptionId: string;
        priority: number;
        recommended: boolean;
      }[];
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/embedded-subscription/`,
        data: { title, subscriptions },
      });
    },

    GetEmbeddedSubs: ({ clientId }: { clientId?: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/embedded-subscription/`,
        params: {
          ...(clientId && { clientId }),
        },
      });
    },

    PaymentGateway: {
      IntegratePayment: ({
        type,
        gateway,
        apiKey,
        apiSecret,
        paymentMethods,
        alias,
        publishableKey,
      }: {
        type: string;
        gateway: string;
        apiKey: string;
        apiSecret: string;
        paymentMethods: any;
        alias: string;
        publishableKey: string;
      }) => {
        return apiCaller({
          method: REST_METHODS.POST,
          url: `${API_URL}/payment/gateway`,
          data: { type, gateway, apiKey, apiSecret, paymentMethods, alias, publishableKey },
        });
      },
      getSecretKey: ({ id }: { id: string }) => {
        return apiCaller({
          method: REST_METHODS.PATCH,
          url: `${API_URL}/payment/gateway/${id}/webhook/secret`,
        });
      },
      RefundList: ({
        from,
        to,
        pageNumber,
        showRecords,
        phoneNumberOrEmail,
        id,
        category,
        currency,
        status,
        download,
      }: {
        from?: any;
        to?: any;
        pageNumber?: any;
        showRecords?: any;
        phoneNumberOrEmail?: any;
        category?: any;
        id?: any;
        currency?: any;
        status?: any;
        download?: boolean;
      }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/payment/gateway/refund`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(status && { status }),
            ...(id && { id }),
            ...(category && { category }),
            ...(currency && { currency }),
            ...(phoneNumberOrEmail && { phoneNumberOrEmail }),
            ...(download && { download }),
            ...(pageNumber && { pageNumber }),
          },
        });
      },
      InitiateRefund: ({
        transactionId,
        amount,
        cancelAccess,
      }: {
        transactionId: any;
        amount: number;
        cancelAccess: any;
      }) => {
        return apiCaller({
          method: REST_METHODS.POST,
          url: `${API_URL}/payment/gateway/razorpay/refund`,
          data: { transactionId, amount, cancelAccess },
        });
      },
      GetAllPayment: () => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/payment/gateway`,
        });
      },
      DeletePayment: ({ id }: { id: string }) =>
        apiCaller({
          method: REST_METHODS.DELETE,
          url: `${API_URL}/payment/gateway/${id}`,
        }),
      GetPaymentById: ({ id }: { id: any }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/payment/gateway/:${id}`,
        }),
      UpdatePayment: ({
        id,
        apiKey,
        apiSecret,
        alias,
      }: {
        id: any;
        apiKey?: string;
        apiSecret?: string;
        alias?: string;
      }) =>
        apiCaller({
          method: REST_METHODS.PATCH,
          url: `${API_URL}/payment/gateway/${id}`,
          data: { apiKey, apiSecret, alias },
        }),
    },

    Slp: {
      SlpStats: (startDate: any, endDate: any) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/slp-stats?start=${startDate}&end=${endDate}`,
        });
      },
      UtmSourceDropDownList: ({ start, end, landingPageId }: { start: string; end: string; landingPageId: string }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/slp-stats/landing-page-list/utm-and-source-dropdown-list`,
          params: {
            start,
            end,
            landingPageId,
          },
        });
      },
      UserViewTrends: ({
        startDate,
        endDate,
        type,
        segmentedBy,
      }: {
        startDate: any;
        endDate: any;
        type: any;
        segmentedBy: any;
      }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/slp-stats/user-view-trend?start=${startDate}&end=${endDate}&type=${type}&segmentedBy=${segmentedBy}`,
        });
      },
      LandingPageList: ({ startDate, endDate }: { startDate: string; endDate: string }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/slp-stats/landing-page-list/?start=${startDate}&end=${endDate}`,
        });
      },
      UtmSourceList: ({
        start,
        end,
        landingPageId,
        type,
        values,
        utm,
        sources,
      }: {
        start: string;
        end: string;
        type: string;
        landingPageId: string;
        values: string[];
        utm?: string;
        sources?: string[];
      }) => {
        return apiCaller({
          method: REST_METHODS.POST,
          url: `${API_URL}/analytics/slp-stats/landing-page-list/stats`,
          data: {
            start,
            end,
            landingPageId,
            type,
            values,
            utm,
            sources,
          },
        });
      },

      OtherPaywall: ({
        startDate,
        endDate,
        landingPageId,
        type,
      }: {
        startDate: string;
        endDate: string;
        type: string;
        landingPageId: string;
      }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/slp-stats/landing-page-list/source-type-list?start=${startDate}&end=${endDate}&landingPageId=${landingPageId}&type=${type}`,
        });
      },
      FlowAnalysis: ({ startDate, endDate, type }: { startDate: any; endDate: any; type: string }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/slp-stats/flow-analysis?start=${startDate}&end=${endDate}&type=${type}`,
        });
      },
      ExtendedFlowAnalysis: ({
        start,
        end,
        userType,
        landingPageId,
        tiers,
      }: {
        start: any;
        end: any;
        userType: any;
        landingPageId: string;
        tiers: string[];
      }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/slp-stats/expanded-flow-analysis`,
          params: {
            start,
            end,
            userType,
            landingPageId,
            tiers,
          },
        });
      },
      GetTiersPerLandingPage: ({ start, end, landingPageId }: { start: any; end: any; landingPageId: string }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/slp-stats/landing-page-list/tier-ids-list`,
          params: {
            start,
            end,
            landingPageId,
          },
        });
      },
    },
    PaywallStats: {
      PaywallAccumulatedStats: ({ startDate, endDate, type }: { startDate: string; endDate: string; type: string }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/paywall-stats/accumulates?type=${type}&startDate=${startDate}&endDate=${endDate}&clientId=""`,
        });
      },
      PaywallUserViewTrends: ({
        start,
        end,
        type,
        segmentedBy,
        clientId,
        paywallType,
      }: {
        start: string;
        end: string;
        type: any;
        segmentedBy: any;
        clientId: any;
        paywallType: string;
      }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/paywall-stats/user-view-trend?type=${type}&segmentedBy=${segmentedBy}&start=${start}&end=${end}&clientId=${clientId}&paywallType=${paywallType}`,
        });
      },
      PaywallFlowAnalysis: ({
        start: start,
        end: end,
        userType,
        type,
      }: {
        start: string;
        end: string;
        userType: any;
        type: string;
      }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/paywall-stats/flow-analysis?start=${start}&end=${end}&userType=${userType}`,
          params: {
            ...(type && { type }),
          },
        });
      },
      PaywallPageList: ({
        startDate,
        endDate,
        clientId,
        type,
      }: {
        startDate: string;
        endDate: string;
        clientId: any;
        type: string;
      }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/paywall-stats/paywall-pages-list?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}&type=${type}`,
        });
      },
      PaywallUtmSourceList: ({
        start,
        end,
        paywallId,
        type,
        values,
        utm,
        sources,
      }: {
        start: any;
        end: any;
        type: any;
        paywallId: any;
        values: any[];
        utm?: any;
        sources?: any[];
      }) => {
        return apiCaller({
          method: REST_METHODS.POST,
          url: `${API_URL}/analytics/paywall-stats/segmented-bar-chart`,
          data: {
            start,
            end,
            paywallId,
            type,
            values,
            utm,
            sources,
          },
        });
      },

      PaywallUtmSourceDropDownList: ({ start, end, paywallId }: { start: string; end: string; paywallId: string }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/paywall-stats/utm-and-sources-list`,
          params: {
            start,
            end,
            paywallId,
          },
        });
      },
      PaywallExtendedFlowAnalysis: ({
        start: start,
        end: end,
        // userType,
        clientId,
        paywallId,
        type,
      }: {
        start: string;
        end: string;
        // userType: any;
        clientId: any;
        paywallId: any;
        type: string;
      }) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/analytics/paywall-stats/expanded-flow-analysis?start=${start}&end=${end}&clientId=${clientId}&paywallId=${paywallId}&type=${type}`,
        });
      },
    },

    CreateCoupon: (apiData: FormData) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/subscription/discount-coupon-template`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: apiData,
      });
    },
    GetAllCoupons: ({ clientId }: { clientId: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/discount-coupon-template`,
        params: {
          ...(clientId && { clientId }),
        },
      }),
    DeleteCoupon: ({ _id }: { _id: string }) =>
      apiCaller({
        method: REST_METHODS.DELETE,
        url: `${API_URL}/subscription/discount-coupon-template/${_id}`,
      }),
    EditCoupon: ({ templateId, appendFormData }: { templateId: string; appendFormData: FormData }) =>
      apiCaller({
        method: REST_METHODS.PATCH,
        url: `${API_URL}/subscription/discount-coupon-template/${templateId}`,
        data: appendFormData,
      }),
    DownloadCoupon: (_id: string) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/discount-coupon-template/download/${_id}`,
      }),
    GetDiscountCouponStats: ({ clientId, from, to }: { clientId: string; from: string; to: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/discount-coupon-template/stats`,
        params: {
          clientId,
          from,
          to,
        },
      }),
  },

  admin: {
    UserDetails: ({ userRecogniser }: { userRecogniser: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/admin/user-details/${userRecogniser}`,
      }),
    GetClients: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/admin/clients`,
      }),

    GetDropdownSubscriptions: (clientId: string) =>
      apiCaller({ method: REST_METHODS.GET, url: `${API_URL}/admin/subscription/dropdown`, params: { clientId } }),

    GetDropdownSubscriptionTiers: (subscriptionId: string) =>
      apiCaller({ method: REST_METHODS.GET, url: `${API_URL}/admin/subscription/${subscriptionId}/tiers` }),

    CreateManualPurchase: (data: {
      clientId: string;
      userId: string;
      subscriptionId: string;
      tierId: string;
      renew: boolean;
      expiryDate: string;
    }) => apiCaller({ method: REST_METHODS.POST, url: `${API_URL}/admin/create-purchase`, data: data }),

    GetSamplePurchaseMigrationFile: () =>
      apiCaller({ method: REST_METHODS.GET, url: `${API_URL}/admin/migration/sample-file` }),

    UploadBulkMigrationFile: (form: FormData) =>
      apiCaller({ method: REST_METHODS.POST, url: `${API_URL}/admin/create-purchase-bulk`, data: form }),

    UserPhoneNumbers: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/admin/stats/user-phonenumbers`,
      }),
    PayerDetails: () =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/admin/stats/payer-details`,
      }),
    FetchCoupons: (paginationParams: PaginationParams) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/content/coupons`,
        params: paginationArgsToParams(paginationParams),
      });
    },
    LoginAsClient: ({ clientId }: { clientId: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/admin/login-as-client`,
        params: {
          ...(clientId && { clientId }),
        },
      }),

    stats: {
      RevenueAndGMVStatistics: ({ from, clientId }: { from: string; clientId?: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/revenue`,
          params: {
            ...(from && { from }),
            ...(clientId && { clientId }),
          },
        }),
      ItemizedTransactions: ({
        contentIds,
        from,
        to,
        pageNumber,
        pageSize,
        clientId,
        download,
        type,
      }: paginationWithClientParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/itemized-transactions?${qs.stringify({
            contentIds,
            from,
            to,
            pageNumber,
            pageSize,
            clientId,
            download,
            ...(type && { type }),
          })}`,
        }),
      PurchaseStatistics: ({ timeRange, clientId }: { timeRange: string; clientId?: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/purchases?${qs.stringify({
            timeRange,
            clientId,
          })}`,
        }),
      payerStatistics: ({ timeRange, clientId }: { timeRange: string; clientId?: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/payers`,
          params: {
            ...(timeRange && { timeRange }),
            ...(clientId && { clientId }),
          },
        }),
      PurchasesByCountry: ({ from, to, clientId, purchaseType }: GeoLocationParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/purchases-by-country`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(clientId && { clientId }),
            ...(purchaseType && { purchaseType }),
          },
        }),
      UsersByCountry: ({ from, to, clientId }: BifurcatedPricesParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/users-by-country`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(clientId && { clientId }),
          },
        }),
      UTMParameterStats: ({
        contentIds,
        from,
        to,
        utmParameter,
        clientId,
        subscriptionIds,
        onlySubscriptions,
        purchasesOnly,
      }: UTMParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/utm-parameter-stats?${qs.stringify({
            contentIds,
            from,
            to,
            utmParameter,
            clientId,
            subscriptionIds,
            onlySubscriptions,
            purchasesOnly,
          })}`,
        }),
      CouponStats: ({ from, to, clientId }: BifurcatedPricesParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/coupon?${qs.stringify({
            from,
            to,
            clientId,
          })}`,
        }),
      TagsStats: ({ contentIds, from, to, pageNumber, pageSize, clientId }: paginationWithClientParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/tags/purchases?${qs.stringify({
            contentIds,
            from,
            to,
            pageNumber,
            pageSize,
            clientId,
          })}`,
        }),
      userStatistics: ({ timeRange, clientId }: { timeRange: string; clientId?: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/users?${qs.stringify({
            timeRange,
            clientId,
          })}`,
        }),
      SubscriberStats: ({ from, to, clientId, download, type }: paginationWithClientParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/subscribers?${qs.stringify({
            from,
            to,
            clientId,
            download,
            type,
          })}`,
        }),
      BifurcatedPrices: ({ from, to, clientId }: BifurcatedPricesParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/bifurcated-transactions`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(clientId && { clientId }),
          },
        }),
      TotalUsers: ({ from, to, slice }: TotalUsersParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/user-count`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(slice && { slice }),
          },
        }),
      TotalWalletAddition: ({ from, to, slice }: TotalWalletAdditionParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/wallet-addition`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(slice && { slice }),
          },
        }),
      PassStats: ({ from, to, slice, clientId }: ConcurrentReadersParams) => {
        return apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/purchased-passes`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(slice && { slice }),
            ...(clientId && { clientId }),
          },
        });
      },

      AggregatedConsumption: ({ from, to, slice, columns, clientId }: AggregatedConsumptionParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/aggregated`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            columns,
            ...(slice && { slice }),
            ...(clientId && { clientId }),
          },
        }),

      ConcurrentReaders: ({ from, to, slice, clientId }: ConcurrentReadersParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/concurrent-readers`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(slice && { slice }),
            ...(clientId && { clientId }),
          },
        }),

      WalletBalance: () =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/wallet-balance`,
        }),
      TopStories: ({ from, to, sortBy, pageNumber, pageSize }: TopStoriesParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/top-contents`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            sortBy,
            ...(pageNumber && { pageNumber }),
            ...(pageSize && { pageSize }),
          },
        }),
      UserCountByBalance: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/user-balance`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      ActiveUsers: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/active-users`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      PreAggregatedUserData: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/aggregated-user-data`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      TopUsers: ({ from, to, clientId }: BifurcatedPricesParams) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/top-users`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
            ...(clientId && { clientId }),
          },
        }),
      viewsByDevice: ({ clientId }: { clientId: string }) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/client/stats/users/device`,
          params: {
            ...(clientId && { clientId }),
          },
        }),
      NewUserStats: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/new-user`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      InactiveUsersPhoneNumbers: ({ from, to }: Dates) =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/inactive-users-phonenumbers`,
          params: {
            ...(from && { from }),
            ...(to && { to }),
          },
        }),
      TransactionCsvsWithGst: () =>
        apiCaller({
          method: REST_METHODS.GET,
          url: `${API_URL}/admin/stats/monthly-transactions-with-gst`,
        }),
    },
  },
  notification: {
    getClientNotifications: (paginationParams: PaginationParams) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/notification/client`,
        params: paginationArgsToParams(paginationParams),
      });
    },
    markNotificationsAsRead: () => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/notification/client/mark-read`,
      });
    },
  },
  subscription: {
    SubscriptionDetails: ({ clientId, currency }: { clientId: string; currency?: currencyType }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/convert-subscription-currency`,
        params: { clientId, currency },
      });
    },
    getSubscriptionPrice: ({
      subId,
      tierId,
      clientId,
      couponcode,
    }: {
      subId: string;
      tierId: string;
      clientId: string;
      couponcode: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/subscription/price`,
        params: { subscriptionId: subId, tierId, clientId, couponCode: couponcode },
      });
    },
    SubscriptionStats: ({ from, to, slice, clientId }: ConcurrentReadersParams) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/stats/aggregated`,
        params: {
          ...(from && { from }),
          ...(to && { to }),
          ...(slice && { slice }),
          ...(clientId && { clientId }),
        },
      });
    },
    SubscriptionAccessCheck: ({ clientId, sessionId }: { clientId: string; sessionId: string }) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/subscription/access`,
        params: {
          ...(clientId && { clientId }),
          ...(sessionId && { sessionId }),
        },
      }),
    ContentAndSubscriptionTableStats: ({ clientId, from, to, pageNumber, pageSize }: SubscriptionStatsParams) =>
      apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/subscription/stats/content-or-subscription-page`,
        params: {
          ...(clientId && { clientId }),
          ...(from && { from }),
          ...(to && { to }),
          ...(pageNumber && { pageNumber }),
          ...(pageSize && { pageSize }),
        },
      }),
  },
  analyst: {
    eventPost: (data: any) => {
      navigator.sendBeacon(
        `${process.env.REACT_APP_KAFKA}collect/event`,
        JSON.stringify({
          messageBody: [{ ...data }],
        }),
      );
    },
  },

  internal: {
    LogError: (error: Record<string, any>): Promise<AxiosResponse<unknown>> => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL}/auth/log-error`,
        data: { severity: 'ERROR', message: 'frontend-log-error', ...error },
      });
    },
  },
};
export default API;
