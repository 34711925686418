export function IsJWTExpired(token: string) {
  if(!token) return true;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const jwtObject = JSON.parse(window.atob(base64));
  const expiry = jwtObject.exp;
  return Date.now() >= expiry * 1000;
}

export function GetJwtPayload(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}