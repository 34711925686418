import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { setAuthDetails } from 'views/Auth/authSlice';
import { OverlayContext } from '../OverlayContext';
import { useSelector } from 'react-redux';
import { postMessage } from '../paywallUtils';
import { RootState } from 'app/rootReducer';
import style from './loginAndVerify.module.scss';
import { Wrapper } from '../components';
import { phoneLogin, emailLogin } from '../../Auth/authUtils';
import UserSignUpAndLogin from 'views/ClientDashboard/loginUI/UserSignUpAndLogin';
import { GetLoginCustomizationdata } from '../../ClientDashboard/login/loginUtils';
import VerifyOTP from 'views/ClientDashboard/loginUI/VerifyOTP';
import API from 'api';
import { getDefaultTrackingValues } from 'utils/Tracker/track';
import { eventLocation, eventType } from 'utils/Tracker/track';
import { setAnalyticsData } from 'app/analyticsSlice';
import mobileRedirectionMessage from 'utils/notifyAppToRedirect';

enum SCREENS {
  LOGIN = 'login',
  OTP = 'otp',
}
export default function Login() {
  const { userDetails, setUserDetails, updateView, postLoginFlow, standaloneMode, redirectMode } = useContext(
    OverlayContext,
  );
  const dispatch = useDispatch();
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  const [loginType, setLoginType] = useState<'PHONE' | 'EMAIL'>('PHONE');

  const [uiConfig, setUiConfig] = useState({} as any);
  const [otpConfig, setOtpConfig] = useState({} as any);
  const [clientId, setClientId] = useState('');
  const [alreadyRegistered, setAlreadyRegistered] = useState(Boolean);
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [screen, setScreen] = useState(SCREENS.LOGIN as SCREENS);
  const paywallType = analyticsStore.paywallType;
  const templateId = analyticsStore.templateId;
  const params = new URLSearchParams(window.location.search);
  const anonId = params.get('anonId') as string;
  const paywallId = params.get('paywallId') || '';
  const [error, setError] = useState('');
  const [data, setdata] = useState({
    enableEmailLogin: true,
    enableMobileLogin: true,
    enableFacebookLogin: false,
    enableGoogleLogin: false,
    enableNameInput: 'SKIP',
    enableSecondaryInput: 'SKIP',
    brandLogoUrl: '',
    uiConfig: {},
  });

  const sendOTP = async () => {
    if (loginType === 'PHONE') {
      console.log('sdfsdfsdf phone');
      const { accountDetails } = await phoneLogin({
        phoneNumber: userPhone || userDetails.phoneNumber,
        clientId: clientId,
        isClientLoginFlow: true,
        eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
        anonId: anonId,
        paywallId,
        paywallType,
      });
      dispatch(setAnalyticsData({ alreadyRegistered: accountDetails?.alreadyRegistered }));
      contentOtpView(accountDetails?.alreadyRegistered);
      contentOtpClick('MOBILE', accountDetails?.alreadyRegistered);
      setAlreadyRegistered(accountDetails?.alreadyRegistered);
      // firstTimeLogin = accountDetails.alreadyRegistered;
    } else {
      console.log('sdfsdfsdf email');
      const { accountDetails } = await emailLogin({
        clientId,
        email: userEmail || userDetails.email,
        isClientLoginFlow: true,
        eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
        anonId: anonId,
        paywallId,
        paywallType,
      });
      dispatch(setAnalyticsData({ alreadyRegistered: accountDetails?.alreadyRegistered }));
      contentOtpView(accountDetails?.alreadyRegistered);
      contentOtpClick('EMAIL', accountDetails?.alreadyRegistered);
      setAlreadyRegistered(accountDetails?.alreadyRegistered);
    }
  };
  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const userDetailsFromParams = params.get('userDetails');

      if (userDetailsFromParams) {
        console.log(JSON.parse(userDetailsFromParams), 'userDetailsFromParams2');
        const newUserDetails = JSON.parse(userDetailsFromParams);
        setUserDetails({
          ...userDetails,
          ...newUserDetails,
        });
      }

      const clientId = params.get('clientId');
      setClientId(clientId || '');
      const { data, error } = await GetLoginCustomizationdata({ clientId: clientId || '' });
      if (error) {
        return message.error('Error while fetching data for customized login view');
      }
      setdata(data);
      const config = JSON.parse(data.uiConfig);
      setUiConfig(config?.signIn);
      setOtpConfig(config?.otp);
      contentLoginView(clientId || '');
      updateView({ action: 'LOGIN' });
    })();
  }, []);

  useEffect(() => {
    //TO REDIRECT TO THE OTP SCREEN IF THE DETAILS ARE BEING PASSED

    if (userDetails.email) {
      setLoginType('EMAIL');
      setUserEmail(userDetails.email);
      onScreenChange(SCREENS.OTP);
    } else if (userDetails.phoneNumber) {
      setLoginType('PHONE');
      setUserPhone(userDetails.phoneNumber);
      onScreenChange(SCREENS.OTP);
    }
  }, [userDetails]);

  const onScreenChange = (screentype: SCREENS) => {
    setScreen(screentype);
  };

  useEffect(() => {
    if (screen === SCREENS.OTP) {
      sendOTP();
    }
  }, [screen]);

  const submitOTP = async (otp: string) => {
    // setShowResendCode(false);
    const loginResponse = await API.auth.LoginOtp({
      email: loginType === 'EMAIL' ? userEmail : undefined,
      phoneNumber: loginType === 'PHONE' ? userPhone : undefined,
      OTP: otp,
      clientId,
      eventLocation: eventLocation.CONTENT_FLOW_OTP,
      paywallId,
      paywallType,
      anonId,
      firstTimeLogin: !alreadyRegistered,
    });
    if (API.validators.matchStatusCode(loginResponse, 401)) return setError(loginResponse.data.message);
    if (API.validators.checkSuccessCode(loginResponse)) {
      const { token, email, phoneNumber, userId, sessionId, expiresAt, version } = loginResponse.data as any;
      dispatch(setAuthDetails({ sessionId, expiresAt, token, userId, email, userType: 'USER', version }));
      const userDetailsResponse = await API.user.Details();
      if (!API.validators.checkSuccessCode(userDetailsResponse)) {
        throw { message: 'Unable to fetch user details' };
      }
      await postLoginFlow({ userId, setError, sessionId, templateId, email, phoneNumber, paywallType });
      postMessage.LOGGEDIN(true, sessionId, userId);
      if (paywallType === 'REGWALL') mobileRedirectionMessage();
      const name = userDetailsResponse.data.name;
      setUserDetails({
        ...userDetails,
        loggedIn: true,
        walletBalance: parseFloat('0'),
        phoneNumber: userDetailsResponse.data.phoneNumber,
        email: userDetailsResponse.data.email,
        name,
        userId: userDetailsResponse.data._id,
        // walletCurrency: userDetailsResponse.data.wallet['currency'],
      });
    } else {
      setError(loginResponse.data.message);
    }
  };

  const contentLoginView = (clientId: string) => {
    const ssoLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
      clientId: clientId,
      // contentId: ,
      anonId: anonId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
      paywallId,
      paywallType,
    };
    API.analyst.eventPost({ ...ssoLoginViewObject });
  };
  const contentOtpView = (alreadyRegistered: boolean) => {
    const ssoLoginViewObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.VIEW,
      eventLocation: eventLocation.CONTENT_FLOW_OTP,
      clientId: clientId,
      // contentId: clientDetails.clientContentId,
      anonId: anonId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
      paywallId,
      paywallType,
      firstTimeLogin: !alreadyRegistered ? 1 : 0,
    };
    API.analyst.eventPost({ ...ssoLoginViewObject });
  };
  const contentOtpClick = (loginType: 'EMAIL' | 'MOBILE' | 'GOOGLE', alreadyRegistered: boolean) => {
    const ssoLoginClickObject = {
      ...getDefaultTrackingValues(),
      eventType: eventType.CLICK,
      eventLocation: eventLocation.CONTENT_FLOW_LOGIN,
      clickAction: loginType,
      clientId: clientId,
      // contentId: clientDetails.clientContentId,
      anonId: anonId,
      url: decodeURIComponent(new URLSearchParams(location.search).get('pageUrl') || ''),
      paywallId,
      paywallType,
      firstTimeLogin: !alreadyRegistered ? 1 : 0,
    };
    API.analyst.eventPost({ ...ssoLoginClickObject });
  };
  return (
    <Wrapper transition={!standaloneMode} hideCross={standaloneMode && !redirectMode} hideFooter={true}>
      {screen === SCREENS.LOGIN && (
        <div className={style['container']}>
          <UserSignUpAndLogin
            type="CONTENT_FLOW"
            clientId={clientId}
            setPhoneNumber={setUserPhone}
            setUserEmail={setUserEmail}
            setLoginType={setLoginType}
            onScreenChange={onScreenChange}
            data={data}
            uiConfig={uiConfig}
            isNotBorder
            loginClick={contentOtpClick}
          />
        </div>
      )}
      {screen === SCREENS.OTP && (
        <>
          <VerifyOTP
            clientId={clientId}
            isNotBorder
            type="SSO"
            data={data}
            userPhoneNumber={userPhone}
            userEmail={userEmail}
            loginType={loginType}
            otpConfig={otpConfig}
            submitOTP={submitOTP}
            setError={setError}
            error={error}
          />
        </>
      )}
    </Wrapper>
  );
}
