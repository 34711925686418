/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { autoLoginMobileMessageHandler } from 'utils/notifyAppToRedirect';
import API, { API_URL } from 'api';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { antdMessageError } from 'utils/antdMessageComponent';
import { userActions } from '../../consts';
import { RootState } from 'app/rootReducer';
import { clearAuthDetails } from 'views/Auth/authSlice';
import { postMessage } from './paywallUtils';
import { getCurrencySymbol } from '../../data/getCurrencySymbol';
import { fbPixelConfig, fireFacebookPixel } from '../../utils/fbPixelConfig';
import { includeGtag, purchaseEventGA } from '../../utils/gtagConfig';
import { includePixel } from '../../utils/pixel';
import axios from 'axios';
import mobileRedirectionMessage from 'utils/notifyAppToRedirect';
import { eventLocation } from 'utils/Tracker/track';

export enum CLIENT_ERRORS {
  MISSING_CLIENT_ID = 'Client id is missing',
  MISSING_CONTENT_ID = 'Content id is missing',
}

export enum PURCHASE_MODE {
  CONTENT = 'CONTENT',
  PASS = 'PASS',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum ROUTES {
  WELCOME = '',
  LOGIN = 'login',
  VERIFY = 'verify',
  EMAIL = 'email',
  BALANCE = 'balance',
  ADD_INFO = 'addInfo',
  TRANSITION_WELCOME = 'transitionWelcome',
  TRANSITION_THANKYOU = 'transitionThankYou',
  PAYMENT_CONFIRMED = 'paymentConfirmed',
  CONFIRM_PAYMENT = 'confirmPayment',
  COUPON = 'coupon',
  METERBANNER = 'meterBanner',
}

export const defaultUserDetails = {
  loggedIn: false,
  walletBalance: 0,
  contentPrice: 0,
  contentDuration: 0,
  formattedContentPrice: '-',
  hasAccess: false,
  phoneNumber: '',
  email: '',
  userId: '',
  contentCurrency: '',
  userCurrency: '', // already set user currency.
  contentTitle: '',
  consumptionVerb: '',
  contentType: '',
  clientName: '',
  contentIsBundle: false,
  contentPixels: {},
  subscriptionText: '',
  subscriptionTitle: '',
  crossButtonClickUrl: '',
  brandLogoUrl: '',
  contentLogoUrl: '',
  subscriptionPageMessage: '',
  buttonStyle: {},
  passPrice: 0,
  formattedPassPrice: '-',
  micropaymentDisplaytext: '',
  passCurrency: '',
  passExists: false,
  micropricingExists: false,
  passTitle: '',
  passDuration: 0,
  subscriptionsExist: false,
  subscriptionDomain: '',
  detectedCountry: '',
  secondaryEmail: '',
  secondaryPhoneNumber: '',
  popupId: '',
  name: '',
  pathUrl: '',
  isDefault: true,
};

export const defaultCouponDetails = {
  code: '',
  newUsersOnly: false,
};

export const passCouponDetails = {
  code: '',
  newUsersOnly: false,
};

export const defaultClientDetails = {
  clientId: '',
  clientContentId: '',
  clientStoryId: '',
  hideSubscription: false,
};

type Subscription = {
  _id: string;
  currency: string;
  duration: number;
  price: number;
  title: string;
};

export type contentDetailsData = {
  title: string;
  price: number;
  duration: number;
  url: string;
  currency: string;
  contentType: string;
  couponDetails: { code: string; newUsersOnly: boolean };
  clientName: string;
  bundle: boolean;
  userDetails?: {
    ampReaderIds: string[];
    country: string;
    phoneNumber: string;
    usedCoupons: string[];
    wallet: { balance: { $numberDecimal: string } };
    _id: string;
    userCurrency: string;
    secondaryEmail: string;
    secondaryPhoneNumber: string;
    name?: string;
    email?: string;
  };
  pixels: any;
  paywallCustomization: {
    buttonStyle: string;
    subscriptionText: string;
    micropaymentDisplaytext: string;
    subscriptionTitle: string;
    crossButtonClickUrl: string;
    brandLogoUrl: string;
  };
  validPass: {
    price: number;
    currency: string;
    title: string;
    duration: number;
  };
  microPricing: {
    price: number;
    duration: number;
  };
  subscriptionsExist: boolean;
  subscriptionDomain: string;
  downloadExists: boolean;
  pathUrl: string;
  detectedCountry: string;
  couponForPass: { code: string; newUsersOnly: boolean };
  logoUrl: string;
};

export type clientSuccessPayload = {
  readId?: string;
  consumptionId: string;
  signature: string;
  payload: {
    clientId: string;
    storyId?: string;
    contentId: string;
    transactionAmount: number;
    createdAt: Date;
  };
  hideFab?: boolean;
  downloadExists: boolean;
  downloadFileName: string;
};

export type contentAccessData = {
  accessTimeLeft: number;
  message?: string;
  payload: {
    clientId: string;
    contentId: string;
    transactionAmount: number;
    createdAt: Date;
  };
  sessionExists: boolean;
  freeAccess: boolean;
  downloadExists: boolean;
  downloadFileName: string;
  consumptionId: string;
  signature: string;
  isAdfreePurchased: boolean;
  unlockedWithRegwall: boolean;
};

export const conflictErrorMessage = 'Price of the content updated, please refresh the page';

export const OverlayContext = createContext({
  onlyLoginFlow: false,
  setOnlyLoginFlow: (o: any) => undefined as any,
  globalError: '',
  redirectMode: false,
  standaloneMode: false,
  fullscreenMode: false,
  routeData: {} as Record<string, any>,
  setRouteData: (d: Record<string, unknown>) => undefined as any,
  purchaseMode: 'CONTENT' as PURCHASE_MODE,
  contentPurchaseType: {
    digital: false,
    adFree: false,
  },
  setPurchaseMode: (o: PURCHASE_MODE) => undefined as any,
  route: '' as ROUTES,
  setRoute: (o: ROUTES) => undefined as any,
  setGlobalError: (o: any) => undefined as any,
  notifyClientSuccess: (successPayload: clientSuccessPayload): void => undefined,
  notifyClientError: (e: CLIENT_ERRORS): void => undefined,
  clientDetails: defaultClientDetails,
  setClientDetails: (o: typeof defaultClientDetails) => undefined as any,
  couponDetails: defaultCouponDetails,
  setCouponDetails: (o: typeof defaultCouponDetails) => undefined as any,
  couponForPass: passCouponDetails,
  setCouponForPass: (o: typeof passCouponDetails) => undefined as any,
  userDetails: defaultUserDetails,
  setUserDetails: (o: typeof defaultUserDetails) => undefined as any,
  redirectToSubscriptionPage: (landingPage?: string, paywallType?: string) => undefined as any,
  redirectToClientSignInPage: () => undefined as any,
  redirectUrlFromDashboard: '' as string,
  setRedirectUrlFromDashboard: (o: any) => undefined as any,
  setLoading: (o: any) => undefined as any,
  updateView: (o: { action: userActions; userId?: string; paywallId?: string }) => undefined as any,
  showSubscriptionOnly: false as boolean,
  setShowSubscriptionOnly: (o: any) => undefined as any,
  hideSubscription: false as boolean,
  hideElementIfPossible: {
    micropricing: false as boolean,
    pass: false as boolean,
    subscription: false as boolean,
  },
  loading: true as boolean,
  downloadExists: false as boolean,
  buttonMode: false as boolean,
  showLogin: false as boolean,
  accentColor: '#3c465c' as string,
  payForContent: async ({
    setError,
    setLoading,
    setShowPaidCheck,
    updateViewAction,
    couponCode,
    type,
    contentPurchaseType,
    subscriptionDetails,
    popupId,
  }: {
    setError?: (errorMsg: string) => any;
    setLoading?: (state: boolean) => any;
    setShowPaidCheck: (state: boolean) => any;
    updateViewAction?: userActions;
    couponCode?: string;
    type: 'CONTENT' | 'PASS' | 'SUBSCRIPTION';
    contentPurchaseType?: {
      digital: boolean;
      adFree: boolean;
    };
    subscriptionDetails?: {
      _id: string;
      amount: number;
      currency: string;
    };
    popupId?: string;
  }) => undefined as any,
  viewId: '',
  setViewId: (o: string) => undefined as any,
  getUtmParameters: () => undefined as any,
  getFormattedPrice: (contentPrice: number, currency: string) => '' as string,
  postLoginFlow: ({
    userId,
    setError,
    sessionId,
    templateId,
    email,
    phoneNumber,
    paywallType,
  }: {
    userId: string;
    setError?: (error: string) => any;
    sessionId: string;
    templateId?: string;
    email?: string;
    phoneNumber?: number;
    paywallType?: string;
  }) => undefined as any,
});

interface Props {
  children: JSX.Element;
}

export const ContextWrapper = ({ children }: Props) => {
  const searchParams = new URLSearchParams(location.search);
  const authDetails = useSelector((store: RootState) => store.auth);
  const analyticsStore = useSelector((store: RootState) => store.analytics);
  const dispatch = useDispatch();
  const [globalError, setGlobalError] = useState('');
  let defaultRoute = ROUTES.WELCOME;
  if (searchParams.get('paymentSuccessful')) defaultRoute = ROUTES.PAYMENT_CONFIRMED;

  if (searchParams.get('transactionId')) defaultRoute = ROUTES.CONFIRM_PAYMENT;
  const [route, setRoute] = useState<ROUTES>(defaultRoute);
  const [purchaseMode, setPurchaseMode] = useState<PURCHASE_MODE>(
    (searchParams.get('purchaseMode') as PURCHASE_MODE) || PURCHASE_MODE.CONTENT,
  );
  const [onlyLoginFlow, setOnlyLoginFlow] = useState(false);
  const [routeData, setRouteData] = useState<Record<string, unknown>>({});
  const [userDetails, setUserDetails] = useState(defaultUserDetails);
  const [viewId, setViewId] = useState(searchParams.get('viewId') || '');
  const [downloadExists, setDownloadExists] = useState(searchParams.get('download') === 'true');
  const [couponDetails, setCouponDetails] = useState(defaultCouponDetails);
  const [couponForPass, setCouponForPass] = useState(passCouponDetails);
  const [clientDetails, setClientDetails] = useState<typeof defaultClientDetails>(
    process.env.NODE_ENV === 'production'
      ? defaultClientDetails
      : {
          clientId: '5f92a62013332e0f667794dc',
          clientContentId: 'Client Story Id 1',
          clientStoryId: 'Client Story Id 1',
          hideSubscription: false,
        },
  );
  const [redirectUrlFromDashboard, setRedirectUrlFromDashboard] = useState('');
  const [showSubscriptionOnly, setShowSubscriptionOnly] = useState(false);
  const [loading, setLoading] = useState(true);

  const standaloneMode =
    !!searchParams.get('loginChallenge') ||
    !!searchParams.get('rid') ||
    !!searchParams.get('download') ||
    window.location === window.parent.location; // check for if not in iframe
  const loginChallengeId = searchParams.get('loginChallenge');
  const clientId = searchParams.get('clientId') as string;
  const clientContentId = searchParams.get('clientContentId') || (searchParams.get('clientStoryId') as string);
  const hideSubscription = !!searchParams.get('hideSubscription') || standaloneMode;
  const sessionId = authDetails.sessionId || searchParams.get('sessionId') || '';
  const templateIdForPaywall = analyticsStore.templateId || searchParams.get('templateId') || '';
  const buttonMode = !!searchParams.get('buttonMode') && !standaloneMode;
  const showLogin = searchParams.get('showLogin') === 'true';
  const accentColor = searchParams.get('accentColor') || '#3c465c';
  const contentPurchaseType = { digital: true, adFree: false };
  const params = new URLSearchParams(window.location.search);
  if (searchParams.get('contentPurchaseType') === 'adfree') {
    contentPurchaseType.adFree = true;
    contentPurchaseType.digital = false;
  }

  const hideQueryString = searchParams.get('hide') || '000';
  const hideElementIfPossible = {
    micropricing: hideQueryString[0] === '1',
    pass: hideQueryString[1] === '1',
    subscription: hideQueryString[2] === '1',
  };

  const setPurchaseModeFromParams = () => {
    const purchaseMode = searchParams.get('purchaseMode') as PURCHASE_MODE;
    if (purchaseMode && Object.values(PURCHASE_MODE).includes(purchaseMode)) {
      setPurchaseMode(purchaseMode);
    }
  };

  useEffect(() => {
    if (searchParams.get('clientId') && searchParams.get('clientContentId')) {
      setClientDetails({
        clientId,
        clientContentId,
        clientStoryId: clientContentId,
        hideSubscription: !!hideSubscription,
      });
    }
    if (!clientId) {
      notifyClientError(CLIENT_ERRORS.MISSING_CLIENT_ID);
      setShowSubscriptionOnly(true);
    }
    if (!clientContentId) {
      notifyClientError(CLIENT_ERRORS.MISSING_CONTENT_ID);
      setShowSubscriptionOnly(true);
    }
    setPurchaseModeFromParams();
  }, [location.search]);

  async function checkAccess(args?: { sessionId: string; userId: string; templateId?: string }) {
    // Check for any form of access to the article
    const contentAccessResponse = await API.content.AccessCheck({
      clientContentId,
      clientId: clientId,
      sessionId: args?.sessionId || sessionId,
      userId: args?.userId || authDetails.userId,
      templateId: args?.templateId || templateIdForPaywall,
    });

    const contentAccessData = contentAccessResponse.data as contentAccessData & { hideFab?: boolean };

    if (contentAccessData.accessTimeLeft > 0) {
      if (contentAccessData.freeAccess) contentAccessData.hideFab = true;
      return {
        hasAccess: true,
        freeAccess: contentAccessData.freeAccess,
        sessionExists: contentAccessData.sessionExists,
        contentAccessData: contentAccessData as contentAccessData,
        isAdfreePurchased: contentAccessData.isAdfreePurchased,
      };
    }
    return { hasAccess: false, sessionExists: contentAccessData.sessionExists };
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { hasAccess, contentAccessData, sessionExists, isAdfreePurchased } = await checkAccess();
        //  In case of adfree pass we are checking for isAdfreePurchased so that same pass is not purchased again
        if ((contentPurchaseType.digital && hasAccess) || (contentPurchaseType.adFree && isAdfreePurchased)) {
          updateView({ action: 'ACCESS' });
          setUserDetails({ ...userDetails, hasAccess: true });
          if (searchParams.get('status') === 'SUCCESS') {
            setRouteData(contentAccessData as clientSuccessPayload);
            fbPixelConfig('Purchase', {
              currency: `INR`,
              value: userDetails.contentPrice,
              status: true,
              content_ids: clientContentId,
            });
            return setRoute(ROUTES.TRANSITION_THANKYOU);
          } else {
            setRoute(ROUTES.PAYMENT_CONFIRMED);
            return notifyClientSuccess(contentAccessData as clientSuccessPayload);
          }
        }

        if (!sessionExists) {
          console.log('logged user out');
          dispatch(clearAuthDetails());
        }
        const contentAndUserDetails = await loadUserAndContentDetails({ forceLogout: sessionExists ? false : true });
        if (contentAndUserDetails) {
          const gaTrackingId = contentAndUserDetails?.contentDetailsData?.pixels?.google?.trackingId;
          if (gaTrackingId) {
            includeGtag(gaTrackingId);
          }
        }

        if (!contentAndUserDetails || !contentAndUserDetails?.newUserDetails.loggedIn) {
          setLoading(false);
          dispatch(clearAuthDetails());
          if (standaloneMode) {
            setRoute(ROUTES.LOGIN);
          }
          return;
        }

        const searchParamPurchaseMode = searchParams.get('purchaseMode');

        setLoading(false);
        if (searchParams.get('status') === 'FAILURE') {
          setRouteData({ message: 'Transaction failed. Please try again' });
          return setRoute(ROUTES.BALANCE);
        }

        if (standaloneMode) {
          // associate rid with user if it exists
          if (ampRid) {
            await API.user.AssociateAmpReaderId({ rid: ampRid });
          }
          if (!purchaseMode && !contentAndUserDetails.contentDetailsData.downloadExists) {
            return setRoute(ROUTES.WELCOME);
          }

          const priceToCompare =
            searchParamPurchaseMode === PURCHASE_MODE.CONTENT || !searchParamPurchaseMode
              ? contentAndUserDetails.newUserDetails.contentPrice
              : contentAndUserDetails.newUserDetails.passPrice;

          // if (contentAndUserDetails.newUserDetails.walletBalance < priceToCompare) {
          return setRoute(ROUTES.BALANCE);
          // } else {
          // User
          // return setRoute(ROUTES.CONFIRM_PAYMENT);
          // }
        }
      } catch (err) {
        console.log('caught error');
        initializeError(err);
        console.error(err);
        setShowSubscriptionOnly(true);
      }
    })();
  }, []);

  function initializeError(errorMessage: any) {
    console.error(errorMessage);
    setLoading(false);
    setShowSubscriptionOnly(true);
  }

  useEffect(() => {
    if (loading) return;
    let paywallType = '';
    if (userDetails?.micropricingExists) paywallType += '1';
    else paywallType += '0';
    if (userDetails?.passExists) paywallType += '1';
    else paywallType += '0';
    if (userDetails?.subscriptionsExist || !clientDetails.hideSubscription) paywallType += '1';
    else paywallType += '0';
    updateView({ paywallType: paywallType });
  }, [loading]);
  const ampRid = searchParams.get('rid') || '';
  const ampReturnUrl = searchParams.get('returnUrl') || '';
  useEffect(() => {
    (async () => {
      if (!viewId) {
        let visitId;
        let visitorId;
        try {
          visitId = sessionStorage.getItem('visitId');
          if (!visitId) {
            visitId = uuid();
            sessionStorage.setItem('visitId', visitId as string);
          }
          visitorId = window.localStorage.getItem('visitorId');
          if (!visitorId) {
            visitorId = uuid();
            window.localStorage.setItem('visitorId', visitorId as string);
          }
        } catch (e) {
          console.error('unable to record visitId because cookies are blocked ');
        }

        const createViewResponse = await API.content.CreateView({
          clientId: searchParams.get('clientId') as string,
          clientContentId: searchParams.get('clientContentId') as string,
          sessionId: authDetails.sessionId,
          utmParameters: getUtmParameters(),
          ...(visitId && { visitId }),
          ...(visitorId && { visitorId }),
        });
        postMessage.CREATE_VIEW(createViewResponse.data.viewId);
        window.dispatchEvent(new CustomEvent('view-id-created', { detail: createViewResponse.data.viewId }));
        setViewId(createViewResponse.data.viewId);
        try {
          sessionStorage.setItem('viewId', createViewResponse.data?.viewId);
        } catch (e) {
          console.error('unable to record visitId because cookies are blocked ');
        }
      }
    })();
  }, []);

  const updateView = async ({
    action,
    paywallType,
    userId,
    paywallId,
  }: {
    action?: userActions;
    paywallType?: string;
    userId?: string;
    paywallId?: string;
  }) => {
    let newViewId = '';
    // Incase view is not created in the state variable by this point - we wait for the 'view-id-created' event before updating the view.
    if (!viewId) {
      newViewId = await new Promise((res) => {
        window.addEventListener('view-id-created', (e) => {
          //@ts-ignore
          res(e.detail);
        });
      });
    }

    await API.content.UpdateView({
      viewId: viewId || newViewId,
      ...(action && { action }),
      ...(paywallType && { paywallType }),
      ...(paywallId && { paywallId }),
      userId: authDetails.userId || userId,
      sessionId: authDetails.sessionId,
    });
  };

  const notifyClientSuccess = async (successPayload: clientSuccessPayload & { sessionId?: string }) => {
    // TODO: Remove read ID and storyId when they're not needed anymore in APIs
    successPayload.readId = successPayload.consumptionId;
    //@ts-ignore
    successPayload.payload.storyId = successPayload.payload.contentId;
    // const searchParams = new URLSearchParams(location.search);
    const clientUrl = decodeURIComponent(searchParams.get('pageUrl') as string);

    // const ampRid = searchParams.get('rid');
    // There is a case where there is no sessionId right after the user logs in and dispatch hasn't update the user data
    // This is handled by adding session id to successPayload before its received by this function
    if (authDetails.sessionId) successPayload.sessionId = authDetails.sessionId;

    if (successPayload.downloadExists) {
      const downloadDetails = await axios.get(`${API_URL}/content/download/${successPayload.consumptionId}`);
      const link = document.createElement('a');
      link.href = downloadDetails.data.url;
      link.setAttribute('download', downloadDetails.data.fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }

    mobileRedirectionMessage();
    if (ampRid) {
      await API.user.AssociateAmpReaderId({ rid: ampRid });
      return setTimeout(() => {
        window.location.href = decodeURIComponent(ampReturnUrl) + '#success=true';
      }, 2000);
    }

    if (loginChallengeId) {
      const loginChallegeResponse = await API.user.getLoginChallenge(loginChallengeId);
      if (loginChallegeResponse.status === 200) {
        const { redirectTo } = loginChallegeResponse.data as { redirectTo: string };
        if (redirectTo) {
          return setTimeout(() => {
            window.location.href = redirectTo;
          }, 2000);
        }
      }
    }

    if (clientUrl) {
      window.location.replace(
        clientUrl +
          (clientUrl.indexOf('?') >= 0 ? '&' : '?') +
          `_cscSuccessPayload=${encodeURIComponent(JSON.stringify(successPayload))}`,
      );
    }

    const message = {
      successPayload,
      type: 'success',
    };
    postMessage(message);
  };
  // const searchParams = new URLSearchParams(location.search);
  const anonId = searchParams.get('anonId') || '';
  const paywallId = searchParams.get('paywallId') || '';
  const redirectToSubscriptionPage = (landingPage?: string, paywallType?: string) => {
    updateView({ action: 'SUB' });
    const pathUrl = landingPage ? landingPage : userDetails.pathUrl;
    const domain = userDetails.subscriptionDomain
      ? `https://${userDetails.subscriptionDomain}`
      : process.env.REACT_APP_SUBS_URL;

    window.open(
      `${domain}/${pathUrl}?clientId=${clientDetails.clientId}&anonId=${anonId}&paywallId=${paywallId}&paywallType=${
        paywallType || analyticsStore.paywallType
      }&clientContentId=${clientDetails.clientContentId}${viewId ? `&viewId=${viewId}` : ''}`,
      '_blank',
    );
    // postMessage({
    //   type: 'subscription',
    // });
  };

  const redirectToClientSignInPage = () => {
    postMessage({
      type: 'signin',
    });
  };

  const getUtmParameters = () => {
    try {
      const pageUrlString = new URLSearchParams(location.search).get('pageUrl');
      const pageUrl = pageUrlString ? new URL(decodeURIComponent(pageUrlString)) : undefined;
      const searchParams = pageUrl?.searchParams;
      const currentPageSearchParams = new URL(window.location.href).searchParams;
      const utmParams: Record<string, any> = {};
      const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
      utmKeys.map((utmKey) => {
        if (currentPageSearchParams.get(utmKey)) {
          utmParams[utmKey] = currentPageSearchParams.get(utmKey);
        }
        if (searchParams?.get(utmKey)) {
          utmParams[utmKey] = searchParams.get(utmKey);
        }
      });
      return utmParams;
    } catch (e) {
      return {};
    }
  };

  const notifyClientError = (error: CLIENT_ERRORS) => {
    // TODO: document the errors in the api
    window.parent.postMessage({ source: '_csc-sdk', error: error, type: 'error' }, '*');
  };

  async function payForContent({
    // state functions from components that call this function
    setError,
    setLoading,
    setShowPaidCheck,
    updateViewAction,
    couponCode,
    type,
    contentPurchaseType,
    subscriptionDetails,
    popupId,
  }: {
    setError?: (errorMsg: string) => any;
    setLoading?: (state: boolean) => any;
    setShowPaidCheck: (state: boolean) => any;
    updateViewAction?: userActions;
    couponCode?: string;
    type: 'CONTENT' | 'PASS' | 'SUBSCRIPTION';
    contentPurchaseType?: {
      digital: boolean;
      adFree: boolean;
    };
    subscriptionDetails?: {
      _id: string;
      amount: number;
      currency: string;
    };
    popupId?: string;
  }) {
    setError?.('');
    setLoading?.(true);
    let price = userDetails.contentPrice;
    let currency = userDetails.contentCurrency;
    if (type === 'PASS') {
      price = userDetails.passPrice;
    }
    if (type === 'SUBSCRIPTION') {
      price = subscriptionDetails?.amount as number;
      currency = subscriptionDetails?.currency as string;
    }
    const purchaseContent = await API.user.payment.pgIntegration({
      clientId: clientDetails.clientId,
      clientContentId: clientDetails.clientContentId,
      paywallId: paywallId,
      paywallType: analyticsStore.paywallType,
      firstTimeLogin: !analyticsStore.alreadyRegistered,
      utmParameters: getUtmParameters(),
      couponCode,
      type,
      popupId: userDetails.popupId || popupId,
      purchaseLocation: eventLocation.CONTENT_FLOW_ADD_MONEY_PAGE,
      anonId: anonId,
    });
    setLoading?.(false);

    if (!API.validators.checkSuccessCode(purchaseContent)) {
      // @ts-ignore
      if (purchaseContent.data.message === 'Content Price Mismatch!') {
        setError?.(conflictErrorMessage);
        // @ts-ignore
      } else if (purchaseContent.data.message === 'Insufficient balance. Please load wallet to purchase this content') {
        setGlobalError('Insufficient balance to purchase content');
        return setRoute(ROUTES.BALANCE);
        // @ts-ignore
      } else if (purchaseContent.data.message === 'User already has access to the content.') {
        setError?.('You already have access, please refresh');
      } else if (purchaseContent.status === 401) {
        // TODO: redirect to the login page later, after clearing all user details.
        setError?.('You have been logged out, please refresh the page');
        // @ts-ignore
      } else if (purchaseContent.data.message) {
        // @ts-ignore
        setError?.(purchaseContent.data.message);
      } else {
        // @ts-ignore
        setError?.('A hiccup occured, please refresh the page and retry');
      }
    }

    if (purchaseContent.status === 201) {
      const purchaseContentData = purchaseContent.data as clientSuccessPayload & {
        message?: string;
        purchaseId: string;
      };
      const status = 'SUCCESS';
      delete purchaseContentData.message;

      if (clientContentId === 'Magazine28Jun2021') {
        includePixel('https://intoday.go2cloud.org/aff_l?offer_id=7');
      }

      if (clientContentId === 'BTMAG01') {
        includePixel('https://intoday.go2cloud.org/aff_l?offer_id=14');
      }

      if (clientContentId === 'IndiaTodayHindiMag') {
        includePixel('https://intoday.go2cloud.org/aff_l?offer_id=21');
      }

      //@ts-ignore
      const facebookConversionEvent = userDetails.contentPixels?.facebook?.events.find(
        (o: any) => o.eventType === 'CONVERSION',
      );
      if (facebookConversionEvent) {
        //@ts-ignore
        const fbPixelId = userDetails.contentPixels?.facebook?.pixelId;
        fireFacebookPixel(fbPixelId, facebookConversionEvent.name);
      }

      // updating both simultaneously causes an update error
      if (updateViewAction) {
        await updateView({
          action: updateViewAction,
        });
      }

      updateView({
        action: 'PURCHASE',
      });

      const utmSource = getUtmParameters()['utm_source'] || '';
      purchaseEventGA({
        utmSource,
        contentId: clientContentId,
        purchaseId: purchaseContentData.purchaseId,
        contentTitle: userDetails.contentTitle,
        currency: userDetails.contentCurrency,
        price: userDetails.contentPrice,
        userId: authDetails.userId,
        couponCode,
      });
      setShowPaidCheck(true);
      setTimeout(() => {
        return notifyClientSuccess(purchaseContentData);
      }, 2000);
    }
  }

  function getFormattedPrice(contentPrice: number, currency: string) {
    const displayPrice = contentPrice < 0.01 ? 0.01 : Math.ceil(contentPrice * 100) / 100;
    return `${(getCurrencySymbol(currency) as string) + displayPrice}`;
  }

  // accept sessionId in props because sessionId state variable hasnt been set in some cases
  async function loadUserAndContentDetails(props: { sessionId?: string; userId?: string; forceLogout: boolean }) {
    // Get & store content price details
    const contentDetailsResponse = await API.content.ContentDetails({
      clientContentId,
      clientId,
      sessionId,
      ...props,
      userDateTime: moment().toDate(),
      contentPurchaseType,
    });

    if (contentDetailsResponse.status === 409) {
      //@ts-ignore
      const error = contentDetailsResponse.data.message;
      console.log('Error:', error);
      setLoading(false);
      return initializeError(error);
    } else if (!API.validators.checkSuccessCode(contentDetailsResponse)) {
      setLoading(false);
      return initializeError('failed to get content details');
    }

    const contentDetailsData = contentDetailsResponse.data as contentDetailsData;
    setCouponDetails(contentDetailsData.couponDetails);
    setCouponForPass(contentDetailsData.couponForPass);
    setDownloadExists(contentDetailsData.downloadExists);
    const userConsumptionAction = {
      VIDEO: 'WATCH',
      STORY: 'READ',
      SONG: 'LISTEN',
      PODCAST: 'LISTEN',
      DOWNLOADABLE_MAGAZINE: 'DOWNLOAD',
    };

    const newUserDetails = { ...userDetails };
    if (props?.userId) newUserDetails.userId = props.userId;
    newUserDetails.passPrice = contentDetailsData?.validPass?.price;
    newUserDetails.formattedPassPrice = getFormattedPrice(newUserDetails.passPrice, contentDetailsData.currency);
    newUserDetails.passExists = !!contentDetailsData?.validPass?.price;
    newUserDetails.passCurrency = contentDetailsData?.validPass?.currency;
    newUserDetails.phoneNumber = contentDetailsData.userDetails?.phoneNumber || '';
    newUserDetails.email = contentDetailsData?.userDetails?.email || '';
    newUserDetails.passTitle = contentDetailsData?.validPass?.title;
    newUserDetails.passDuration = contentDetailsData?.validPass?.duration;
    setPurchaseModeFromParams(); // in case of subscription
    newUserDetails.micropricingExists = !!contentDetailsData?.microPricing?.price;
    newUserDetails.contentPrice = contentDetailsData?.microPricing?.price;
    newUserDetails.contentTitle = contentDetailsData.title;
    newUserDetails.contentCurrency = contentDetailsData.currency;
    newUserDetails.formattedContentPrice = getFormattedPrice(newUserDetails.contentPrice, contentDetailsData.currency);
    newUserDetails.contentDuration = contentDetailsData?.microPricing?.duration;
    newUserDetails.clientName = contentDetailsData.clientName;
    if (contentDetailsData.bundle) {
      newUserDetails.contentIsBundle = true;
    }
    newUserDetails.contentType =
      contentDetailsData.contentType === 'DOWNLOADABLE_MAGAZINE' ? 'MAGAZINE' : contentDetailsData.contentType;
    newUserDetails.walletBalance = parseFloat('0');
    // @ts-ignore
    newUserDetails.consumptionVerb = userConsumptionAction[newUserDetails.contentType] || 'UNLOCK';
    newUserDetails.contentPixels = contentDetailsData.pixels;
    newUserDetails.subscriptionText = contentDetailsData.paywallCustomization.subscriptionText;
    newUserDetails.micropaymentDisplaytext = contentDetailsData?.paywallCustomization?.micropaymentDisplaytext;
    newUserDetails.subscriptionTitle = contentDetailsData.paywallCustomization.subscriptionTitle;
    newUserDetails.crossButtonClickUrl = contentDetailsData.paywallCustomization.crossButtonClickUrl;
    newUserDetails.brandLogoUrl = contentDetailsData.paywallCustomization.brandLogoUrl;
    newUserDetails.contentLogoUrl = contentDetailsData.logoUrl;
    newUserDetails.subscriptionsExist = contentDetailsData.subscriptionsExist;
    newUserDetails.subscriptionDomain = contentDetailsData.subscriptionDomain;
    newUserDetails.pathUrl = contentDetailsData.pathUrl;
    newUserDetails.buttonStyle = contentDetailsData.paywallCustomization.buttonStyle;
    if (contentDetailsData.userDetails?.userCurrency)
      newUserDetails.userCurrency = contentDetailsData.userDetails.userCurrency;
    newUserDetails.detectedCountry = contentDetailsData.detectedCountry;
    newUserDetails.secondaryEmail = contentDetailsData.userDetails?.secondaryEmail || '';
    newUserDetails.secondaryPhoneNumber = contentDetailsData.userDetails?.secondaryPhoneNumber || '';
    newUserDetails.name = contentDetailsData.userDetails?.name || '';
    setDownloadExists(contentDetailsData.downloadExists);
    // Logged in check
    const userToken = authDetails.token;
    const userType = authDetails.userType;
    // dispatch isn't synchronous so we have to force log user out
    // Set user logged in with props in case of postlogin flow
    newUserDetails.loggedIn = props.forceLogout ? false : (!!userToken && userType === 'USER') || !!props?.userId;
    newUserDetails.userId = contentDetailsData?.userDetails?._id || '';

    let popupId = '';
    const pageUrlString = new URLSearchParams(location.search).get('pageUrl');
    const pageUrl = pageUrlString ? new URL(decodeURIComponent(pageUrlString)) : undefined;
    const searchParams = pageUrl?.searchParams;
    if (searchParams?.get('popupId')) popupId = searchParams?.get('popupId') as string;
    const currentPageSearchParams = new URL(window.location.href).searchParams;
    if (currentPageSearchParams.get('popupId')) popupId = currentPageSearchParams.get('popupId') as string;
    newUserDetails.popupId = popupId;

    setUserDetails(newUserDetails);
    return { contentDetailsData, newUserDetails };
  }

  async function postLoginFlow({
    userId,
    setError,
    sessionId,
    templateId,
    email,
    phoneNumber,
    paywallType,
  }: {
    userId: string;
    setError?: (error: string) => any;
    sessionId: string;
    templateId?: string;
    email?: string;
    phoneNumber?: number;
    paywallType?: string;
  }) {
    const userAndContentDetails = await loadUserAndContentDetails({ sessionId, userId, forceLogout: false });
    if (!userAndContentDetails) return setError?.('Failed to get content details');
    const { newUserDetails: updatedUser } = userAndContentDetails;
    const { hasAccess, contentAccessData } = await checkAccess({ userId, sessionId, templateId });

    if (loginChallengeId) {
      await API.user.updateLoginChallenge(loginChallengeId, sessionId);
    }
    // associate rid with user if it exists
    if (ampRid) {
      await API.user.AssociateAmpReaderId({ rid: ampRid });
    }

    // if user has not filled name or secondary email
    // redirect user to add info screen

    const secondaryData =
      routeData.loginMode === 'EMAIL' ? updatedUser.secondaryPhoneNumber : updatedUser.secondaryEmail;

    const userHasAddedSecondaryDetails = !!updatedUser.name && !!secondaryData;

    if (hasAccess && contentAccessData) {
      await updateView({ action: 'ACCESS' });
      if (!contentAccessData.unlockedWithRegwall) {
        setRoute(ROUTES.PAYMENT_CONFIRMED);
      }
      return notifyClientSuccess({ ...contentAccessData, sessionId });
    } else if (purchaseMode === PURCHASE_MODE.SUBSCRIPTION) {
      return setRoute(ROUTES.WELCOME); // FIXME: take to the subscription page!?
    } else if (onlyLoginFlow || searchParams.get('onlyLoginFlow')) {
      autoLoginMobileMessageHandler(
        JSON.stringify({
          status: true,
          message: 'login successfully',
          userId,
          email: email,
          phoneNumber: phoneNumber,
          sessionId,
        }),
      );
      if (ampRid) {
        // associate rid with user if it exists
        if (ampRid) {
          const associateAmpResponse = await API.user.AssociateAmpReaderId({ rid: ampRid });
          if (!API.validators.checkSuccessCode(associateAmpResponse))
            return antdMessageError('Unable To Update Segment');
          else {
            window.open(ampReturnUrl, '_self');
          }
        }
      }
      return setRoute(ROUTES.WELCOME);
    } else if (purchaseMode === PURCHASE_MODE.CONTENT) {
      if (paywallType !== 'REGWALL') {
        const nextRoute = ROUTES.BALANCE;
        if (userHasAddedSecondaryDetails) {
          return setRoute(nextRoute);
        } else {
          setRouteData({
            nextPage: nextRoute,
          });
        }
        if (nextRoute === 'balance') return setRoute(ROUTES.BALANCE);
        if (nextRoute === 'confirmPayment') return setRoute(ROUTES.CONFIRM_PAYMENT);
      }
    } else if (purchaseMode === PURCHASE_MODE.PASS) {
      const nextRoute = ROUTES.BALANCE;
      if (userHasAddedSecondaryDetails) {
        return setRoute(nextRoute);
      } else {
        setRouteData({
          nextPage: nextRoute,
        });
      }
      if (nextRoute === 'balance') return setRoute(ROUTES.BALANCE);
      if (nextRoute === 'confirmPayment') return setRoute(ROUTES.CONFIRM_PAYMENT);
    }
    if (!purchaseMode) {
      return setRoute(ROUTES.WELCOME);
    }
    if (routeData?.nextPage === 'balance') {
      return setRoute(ROUTES.BALANCE);
    }
    if (routeData.nextPage === 'confirmPayment') {
      console.log('dfljhuisix');

      return setRoute(ROUTES.CONFIRM_PAYMENT);
    }
    return setRoute(ROUTES.CONFIRM_PAYMENT);
  }

  return (
    <OverlayContext.Provider
      value={{
        route,
        setRoute,
        routeData,
        onlyLoginFlow,
        setOnlyLoginFlow,
        setRouteData,
        fullscreenMode: !!searchParams.get('fullscreen'),
        purchaseMode,
        contentPurchaseType,
        setPurchaseMode,
        globalError,
        setGlobalError,
        notifyClientSuccess,
        notifyClientError,
        userDetails,
        setUserDetails,
        clientDetails,
        setClientDetails,
        redirectToSubscriptionPage,
        redirectToClientSignInPage,
        redirectUrlFromDashboard,
        setRedirectUrlFromDashboard,
        updateView,
        payForContent,
        redirectMode: !!searchParams.get('pageUrl') && standaloneMode,
        standaloneMode,
        viewId: viewId,
        setViewId,
        getUtmParameters,
        getFormattedPrice,
        postLoginFlow,
        couponDetails,
        setCouponDetails,
        couponForPass,
        setCouponForPass,
        loading,
        setLoading,
        hideSubscription,
        showSubscriptionOnly,
        setShowSubscriptionOnly,
        buttonMode,
        showLogin,
        accentColor,
        downloadExists,
        hideElementIfPossible,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};
