import React, { useEffect } from 'react';
import API from 'api';
import { GetJwtPayload, IsJWTExpired } from '../../utils/jwt';
import { useDispatch } from 'react-redux';
import store from 'app/store';
import { setAuthDetails } from 'views/Auth/authSlice';

enum LoginState {
  LoggedIn,
  LoggedOut,
  Loading,
  Errored,
}

export default function SsoRedirectLanding() {
  const [loginState, setLoginState] = React.useState<LoginState>(LoginState.Loading);
  const [error, setError] = React.useState('');

  const loginWithAuthorizationCode = async (authorizationCode: string, redirectUrl: string, clientId: string) => {
    const res = await API.auth.LoginWithAuhtorizationCode({ authorizationCode, clientId });
    if (!API.validators.checkSuccessCode(res)) {
      setError(res.data.message);
      setLoginState(LoginState.Errored);
    }
    if (res.status === 400) {
      setError(res.data.message);
      setLoginState(LoginState.Errored);
      return;
    }
    if (res.status === 200) {
      const payload = GetJwtPayload(res.data.accessToken);
      await store.dispatch(
        setAuthDetails({
          phoneNumber: '',
          token: res.data.accessToken,
          userId: payload.sub,
          userType: 'USER',
          clientId: payload.iss,
          clientName: '',
          email: '',
          sessionId: payload.jti,
          expiresAt: '',
          version: 2,
          clientDetails: [],
          refreshToken: res.data.refreshToken,
        }),
      );
      console.log(await store.getState().auth);
      console.log(redirectUrl);
      window.location.href = redirectUrl;
      return;
    }
    setLoginState(LoginState.Errored);
    setError('unhandeled error');
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const authorizationCode = searchParams.get('authorizationCode');
    const redirectUrl = searchParams.get('redirectUrl');
    const clientId = searchParams.get('clientId');
    console.log({ authorizationCode, redirectUrl, clientId });
    if (authorizationCode && redirectUrl && clientId) {
      loginWithAuthorizationCode(authorizationCode, redirectUrl, clientId);
    } else {
      setLoginState(LoginState.Errored);
    }
  }, []);
  return (
    <>
      {loginState === LoginState.Errored && <div>{error}</div>}
      {loginState === LoginState.Loading && <div>Loading...</div>}
      {loginState === LoginState.LoggedIn && <div>Logged In!!</div>}
    </>
  );
}
