import React, { useEffect, lazy, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { setConfigDetails } from './configslice';
const MeterBanner = lazy(() => import('views/UserOverlay/views/MeterBanner'));
const CheckUserStatus = lazy(() => import('views/UserOverlay/views/CheckUserStatus'));
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthParent from 'views/Auth/AuthParent';
const ContextWrapper = lazy(() => import('views/Dashboard/DashboardContext'));
import { ContextWrapper as ContextWrapperNew } from 'views/UserOverlay/OverlayContext';
const Dashboard = lazy(() => import('views/Dashboard/Dashboard'));
import { setAuthDetails } from 'views/Auth/authSlice';
// Not using lazy load on user overlay for maximum speed + no distortion
import UserOverlay from 'views/UserOverlay/Overlay';
const LoginCheck = lazy(() => import('views/LoginCheck'));
const GoogleLogin = lazy(() => import('views/GoogleLogin'));

const CookieCheck = lazy(() => import('views/CookieCheck'));
const AdminDashboard = lazy(() => import('views/AdminDashboard/AdminDashboard'));
const ClientDashboard = lazy(() => import('views/ClientDashboard/ClientDashboard'));
const GenericErrorPage = lazy(() => import('../components/GenericErrorPage'));
import { RootState } from 'app/rootReducer';
import { consts } from 'utils';
import './App.less';
import LoadingCircle from 'views/UserOverlay/components/loaders/LoadingCircle';
const SubscriptionMain = lazy(() => import('views/Subscription/SubscriptionMain'));
const ConscentLoginOverlay = lazy(() => import('views/ConscentLogin/ConscentLoginOverlay'));
const BundledSubscriptionMain = lazy(() => import('views/BundledSubscriptions/BundledSubscriptionMain'));
const SubsEditPage = lazy(() => import('components/SubsEditPage'));
import { setAnalyticsData } from './analyticsSlice';
const AutoLogin = lazy(() => import('views/ClientDashboard/components/AutoLogin/AutoLogin'));
const UserStatus = lazy(() => import('views/ClientDashboard/components/UserStatus/UserStatus'));
import SsoLoginRedirectLanding from 'views/SsoRedirectLanging';

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  if (window.innerWidth <= 575) {
    dispatch(setConfigDetails({ isMobile: true }));
  } else {
    dispatch(setConfigDetails({ isMobile: false }));
  }

  if (window.innerWidth <= 992) {
    dispatch(setConfigDetails({ isTabletOrMobile: true }));
  } else {
    dispatch(setConfigDetails({ isTabletOrMobile: false }));
  }

  const isMobile = useSelector((state: RootState) => state.config.isMobile);

  const isTabletOrMobile = useSelector((state: RootState) => state.config.isTabletOrMobile);
  const authStore = useSelector((store: RootState) => store.auth);

  useEffect(() => {
    const loginCode = searchParams.get('loginCode');
    const anonId = searchParams.get('anonId') || '';
    dispatch(setAnalyticsData({ anonId }));
    if (loginCode) {
      // remove backslashes in case json stringification added slashes
      dispatch(setAuthDetails({ ...JSON.parse(loginCode.replace(/\\/g, '')), userType: 'CLIENT' }));
      return history.push(consts.CLIENT_HOME_PATH);
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth > 575) {
        dispatch(setConfigDetails({ isMobile: false }));
      } else if (!isMobile && window.innerWidth <= 575) {
        dispatch(setConfigDetails({ isMobile: true }));
      }

      if (isTabletOrMobile && window.innerWidth > 992) {
        dispatch(setConfigDetails({ isTabletOrMobile: false }));
      } else if (!isTabletOrMobile && window.innerWidth <= 992) {
        dispatch(setConfigDetails({ isTabletOrMobile: true }));
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  // Configure subdomain based routing
  const hostname = window.location.hostname;
  let subdomainType: 'ADMIN' | 'CLIENT' | 'USER' | '' = '';
  const domainParts = hostname.split('.');
  if (domainParts.length > 1) {
    const subdomain = domainParts[0];
    if (subdomain.indexOf('client') >= 0) {
      subdomainType = 'CLIENT';
    } else if (subdomain.indexOf('admin') >= 0) {
      subdomainType = 'ADMIN';
    } else {
      subdomainType = 'USER';
    }
    dispatch(setConfigDetails({ subdomainType }));
  }

  const searchParams = new URLSearchParams(location.search);

  const nextUrl = searchParams.get('nextUrl');
  useEffect(() => {
    const redirectUserToSubscriptionsId = searchParams.get('subscription');

    if (redirectUserToSubscriptionsId)
      sessionStorage.setItem('redirectUserToSubscriptionsId', redirectUserToSubscriptionsId);

    // nextUrl is only for client
    if (!authStore.token || authStore.userType !== 'CLIENT') return;
    if (nextUrl) {
      return history.push('dashboard/' + nextUrl);
    }
  });

  return (
    <>
      <Switch>
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_HOME_PATH}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <Suspense fallback={<LoadingCircle />}>
                <ContextWrapper>
                  <Dashboard />
                </ContextWrapper>
              </Suspense>
            </GoogleOAuthProvider>
          </Route>
        )}
        {
          // Using routes within fragments breaks the switch,
          // which is why the user condition is repeated again for a new route
        }
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_OVERLAY_PATH}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <UserOverlay />
            </GoogleOAuthProvider>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_OVERLAY_METER}>
            <Suspense fallback={<LoadingCircle />}>
              <ContextWrapperNew>
                <MeterBanner />
              </ContextWrapperNew>
            </Suspense>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.CHECK_USER_STATUS}>
            <Suspense fallback={<LoadingCircle />}>
              <CheckUserStatus />
            </Suspense>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.SSO_LOGIN_REDIRECT_LANDING}>
            <SsoLoginRedirectLanding />
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.NEW_SRP}>
            <SsoLoginRedirectLanding />
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_LOGIN_CHECK_PATH}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <LoginCheck />
            </GoogleOAuthProvider>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.GOOGLE_LOGIN}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <Suspense fallback={<LoadingCircle />}>
                <GoogleLogin />
              </Suspense>
            </GoogleOAuthProvider>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_COOKIE_CHECK_PATH}>
            <CookieCheck />
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_SUBSCRIPTION_PATH}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <Suspense fallback={<LoadingCircle />}>
                <SubscriptionMain />
              </Suspense>
            </GoogleOAuthProvider>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_BUNDLED_SUBSCRIPTION_PATH}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <Suspense fallback={<LoadingCircle />}>
                <BundledSubscriptionMain />
              </Suspense>
            </GoogleOAuthProvider>
          </Route>
        )}
        <Route path={consts.USER_EDIT_SUBS}>
          <Suspense fallback={<LoadingCircle />}>
            <SubsEditPage phonenumber={authStore.phoneNumber || ''} email={authStore.email || ''} />
          </Suspense>
        </Route>

        <Route path={consts.GENERIC_ERROR_PATH}>
          <Suspense fallback={<LoadingCircle />}>
            <GenericErrorPage />
          </Suspense>
        </Route>

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_CONSCENT_LOGIN}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <Suspense fallback={<LoadingCircle />}>
                <ConscentLoginOverlay />
              </Suspense>
            </GoogleOAuthProvider>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.AUTO_LOGIN_USER}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
              <Suspense fallback={<LoadingCircle />}>
                <AutoLogin />
              </Suspense>
            </GoogleOAuthProvider>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'USER') && (
          <Route path={consts.USER_STATUS}>
            <Suspense fallback={<LoadingCircle />}>
              <UserStatus />
            </Suspense>
          </Route>
        )}

        {(!subdomainType || subdomainType === 'ADMIN') && (
          <Route path={consts.ADMIN_HOME_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <AdminDashboard />
            </Suspense>
          </Route>
        )}
        {(!subdomainType || subdomainType === 'CLIENT') && (
          <Route path={consts.CLIENT_HOME_PATH}>
            <Suspense fallback={<LoadingCircle />}>
              <ClientDashboard />
            </Suspense>
          </Route>
        )}

        <Route path="/">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
            <AuthParent />
          </GoogleOAuthProvider>
        </Route>
      </Switch>
    </>
  );
}

export default App;
